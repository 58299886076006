


























import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { emit } from 'process';
import { useUser } from '~/modules/customer/composables/useUser';
import { eventBus } from "@/almarwan/helpers/EventBus";
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';

export default defineComponent({
  components: {
    SkeletonLoader
  },
  setup() {
    const { customerCards, customerPaymentId, customerSetDefaultCard } = useUser();
    const cardList = ref([]);
    const selectedCard = ref('');
    const loading = ref(false);
    const selectSavedCard = (card) =>{
      selectedCard.value = card.id;
      //emit('savedCardSlelected', card)
      eventBus.$emit('savedCardSlelected', card )
    }
    const removeSelected = () => {
      selectedCard.value = '';
      eventBus.$emit('savedCardSlelected', false )
    }
    const loadCards = async() => {
      loading.value = true;
      const custCards = await customerCards();
      if(custCards['payment'].cards !== null) {
        cardList.value = custCards['payment'].cards
      }
      
      loading.value = false;
    }

    onMounted(async()=> {
      loadCards();
    });

    return {
      cardList,
      selectedCard,
      selectSavedCard,
      removeSelected,
      loading
    }
  },
})
