




















































































































































































































































import {defineComponent, PropType, ref, onMounted, watch, onBeforeUnmount} from '@vue/composition-api'
import type {CartItemInterface} from '~/modules/GraphQL/types';
import {useUiNotification} from '~/composables';
import {useCart} from '~/modules/checkout/composables/useCart';
import {debounce} from 'lodash-es';
import {SfNotification} from '@storefront-ui/vue';
import {eventBus} from '@/almarwan/helpers/EventBus'
import Percentage from "~/modules/checkout/pages/Checkout/PercentagePayment.vue";
import {useRouter} from "@nuxtjs/composition-api";
import useUiHelpers from "../../../../../composables/useUiHelpers";
import SkuTag from "~/modules/catalog/category/components/views/SkuTag.vue";
import {useConfigStore} from "~/stores/config";

export default defineComponent({
  data() {
    return {
      qty: 1,
      activeWarranty: 1,
    }
  },
  props: {
    products: {
      type: Array as PropType<CartItemInterface[]>,
      default: 0,
    },
    selectedCurrency: {
      type: String,
      default: 'USD'
    }
  },
  components: {
    Percentage,
    SfNotification,
    SkuTag
  },
  setup(props) {
    const {
      cart,
      removeItem,
      updateItemQty,
      updateInstallation,
      loading,
      error
    } = useCart();
    const {send: sendNotification, notifications} = useUiNotification();
    const {numberWithCommas} = useUiHelpers()
    const visible = ref(false);
    const router = useRouter();
    const tempProduct = ref();
    const toggleChecked = ref(false);
    const cartLoading = ref(false);
    const showWarrantyOptions = ref(false);
    const wLoading = ref(false);
    const iLoading = ref(false);
    const {storeConfig} = useConfigStore()
    const numberOfInstallments = ref(storeConfig.no_of_installments)
    const sendToRemove = ({product}: { product: CartItemInterface }) => {
      if (notifications.value.length > 0) {
        notifications.value[0].dismiss();
      }
      visible.value = true;
      tempProduct.value = product;
    };

    const actionRemoveItem = async (product: CartItemInterface) => {
      await removeItem({product});
      visible.value = false;

      sendNotification({
        id: Symbol('product_removed'),
        message: product['name'] + ' has been successfully removed from your cart.',
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Product removed',
      });

      setTimeout(() => {
        if (props.products.length === 0) {
          router.push({path: '/'});
        }
      }, 300)
    };

    const updateQuantity = debounce(async ({product, quantity}) => {
      cartLoading.value = true
      await updateItemQty({product, quantity});
      if (error.value.updateItemQty) {
        sendNotification({
          id: Symbol('product_removed'),
          message: 'The requested qty is not available',
          type: 'warning',
          icon: 'check',
          persist: false,
          title: 'Product removed',
        });
      }
    }, 500);
    const updateInstallationStat = debounce(async ({product}, itemObj) => {
      iLoading.value = true;
      let warrantyVals = {};
      let updateVal = null
      let iVal = getInstallationValue(itemObj?.customizable_options);
      let insItem = itemObj?.customizable_options.filter(item => item.label === "Installation");
      let warrantyObj = itemObj?.customizable_options.filter(item => item.label === "Warranty");
      if (warrantyObj.length) {
        warrantyVals = {id: warrantyObj[0].id, value_string: warrantyObj[0].values[0].value}
      }
      let instOptions = itemObj?.product?.options?.filter(item => item.title === "Installation").map(vlas => vlas.value);
      if (iVal) {
        updateVal = instOptions[0]?.filter(item => item.title === 'No')
      } else {
        updateVal = instOptions[0]?.filter(item => item.title === 'Yes')
      }
      await updateInstallation({
        product,
        quantity: 1,
        customizable_options: [{id: insItem[0]?.id, value_string: updateVal[0]?.option_type_id}, warrantyVals],
        custom_option: {reserve_order: true}
      });
    }, 500);

    const warrantyEnabled = (options) => {
      let warrantyCheck = options.filter(item => item.label === "Warranty");
      if (warrantyCheck.length) {
        return true;
      } else {
        return false;
      }

    }
    const getWarrantyLabel = (options) => {
      return options.filter(item => item.label === "Warranty").map(vlas => vlas.values[0].label)[0];
    }

    const getWarrantyPrice = (options) => {
      let waPrice =  options.filter(item => item.label === "Warranty").map(vlas => vlas.values[0].price.value)[0];
      if(waPrice == 0) {
        return 'Free'
      }
      else {
        return props.selectedCurrency +' '+ waPrice
      }
    }

    const getWarrantyValue = (options) => {
      let wValue = options.filter(item => item.label === "Warranty").map(vlas => vlas.values[0]?.label)
      return wValue[0]?.split(' ')[0];
    }
    const setSelectedWarranty = async ({product}, wItem, options) => {
      wLoading.value = true;
      let installVals = {};
      let warrantyObj = options.filter(item => item.label === "Warranty");
      let installObj = options.filter(item => item.label === "Installation");
      if (installObj.length) {
        installVals = {id: installObj[0].id, value_string: installObj[0].values[0].value}
      }
      await updateInstallation({
        product,
        quantity: 1,
        customizable_options: [{id: warrantyObj[0].id, value_string: wItem.option_type_id}, installVals]
      });
      showWarrantyOptions.value = false;
    }
    const getInstallationValue = (options) => {
      let checkedVal = options.filter(item => item.label === "Installation").map(vlas => vlas.values[0].label)[0];
      if (checkedVal === 'Yes') {
        return true
      } else {
        return false
      }
      //return checkedVal
    }
    const getInstallationPrice = (options) => {
      return options.filter(item => item.label === "Installation").map(vlas => vlas.values[0].price.value)[0];
    }
    const installationEnabled = (options) => {
      let stat = false;
      options?.forEach(element => {
        if (element.label === 'Installation') {
          stat = true
          return
        }
      });
      return stat;
    }
    //const price = computed(() => props?.product?.price_range?.minimum_price?.final_price?.value)

    const calculateInstallment = (price) => {
      let amount = price / (numberOfInstallments.value ? numberOfInstallments.value : 4)
      let decimal
      if (!Number.isInteger(amount)) {
        decimal = amount.toFixed(2)
      } else {
        decimal = amount
      }
      return numberWithCommas(decimal)
    }

    watch(() => cart.value, () => {
      wLoading.value = false;
      iLoading.value = false;
    });
    onMounted(() => {
      eventBus.$on('doneCartOperation', (val) => {
        if (val === true) {
          cartLoading.value = false;
        }
      });
      if (props.products && props.products.length === 0) {
        router.push({path: '/'});
      }
    });

    onBeforeUnmount(() => {
      eventBus.$off('doneCartOperation');
    })

    return {
      updateQuantity,
      loading,
      visible,
      sendToRemove,
      actionRemoveItem,
      tempProduct,
      warrantyEnabled,
      getWarrantyLabel,
      getWarrantyPrice,
      getWarrantyValue,
      installationEnabled,
      toggleChecked,
      getInstallationValue,
      updateInstallationStat,
      getInstallationPrice,
      cartLoading,
      showWarrantyOptions,
      setSelectedWarranty,
      wLoading,
      iLoading,
      numberWithCommas,
      calculateInstallment,
      numberOfInstallments
    }

  },
})
