








import { defineComponent } from "@nuxtjs/composition-api";

export default defineComponent({
  name: 'SkuTag',
  props: {
    skuTag: {
      type: String,
      required: true
    }
  },
})
