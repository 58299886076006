<template>
  <div class="payment-form-col">
    <!-- <div id="name"></div> -->
    <div id="card-number"></div>
    <div class="exp-cvc-col">
      <div id="card-expiry"></div>
      <div id="card-cvc"></div>
    </div>
    <div id="card-error"></div>
    <button id="custom-button" class="btn btn--has-icon btn--default btn--orange" :class="updating ? 'disabled' : ''" v-if="showAddCard" @click="createPaymentMethod">
      <template v-if="!updating">
        Submit<i class="icon icon--angle"></i>
      </template>
      <template v-if="updating">
        <span class="loader loader--white"></span>
      </template>
    </button>
  </div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api'
import { eventBus } from "@/almarwan/helpers/EventBus"
import {useCartStore} from "~/modules/checkout/stores/cart";
import { useUser } from '~/modules/customer/composables/useUser';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { ref } from '@nuxtjs/composition-api';

export default defineComponent({
  data () {
    return {
      showAddCard: false,
      updating: false,
      stripe: null
    };
  },
  computed: {
    stripeElements () {
      return this.stripe.elements();
    },
  },
  props: {
    payBalanceOn: {
      type: Boolean,
      default: false
    }
  },
  async mounted () {
    if (process.client) {
      // Load the Stripe.js library dynamically
      const stripe = await loadStripe(process.env.STRIP_PUBLIC_KEY)
      this.stripe = stripe;
    }
    // Style Object documentation here: https://stripe.com/docs/js/appendix/style
    const style = {
      base: {
        color: 'black',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '18px',
        '::placeholder': {
          color: '#aab7c4',
          margin: 'auto',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },

    };
    this.cardNumber = this.stripeElements?.create('cardNumber', { style });
    this.cardNumber.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', { style });
    this.cardCvc.mount('#card-cvc');
    // this.name = this.stripeElements.create('name', { style });
    // this.cardCvc.mount('#name');
    eventBus.$on('submitCart', (val) => {
      if(val === 'buynow') {
        this.createPaymentMethod()
      }
      if(val === 'payBalance') {
        this.createPaymentMethod()
      }
    });
    if(this.$route.path.includes('/my-wallet')) {
      this.showAddCard = true;
    }
    this.cardNumber.on('change', (event) => {
      const cardNumberValue = event;
      if (!cardNumberValue.empty && !cardNumberValue.error) {
       let el = window.document.getElementById('card-number')
        el?.classList.add('active-field');
        el?.classList.remove('has-error');
      } if (cardNumberValue.complete  && !cardNumberValue.error){
        let el = window.document.getElementById('card-number')
        el?.classList.remove('active-field');
        el?.classList.remove('has-error');
      }if ( cardNumberValue.error){
        let el = window.document.getElementById('card-number')
        el?.classList.add('has-error');
      }
    });
    this.cardExpiry.on('change', (event) => {
      const cardNumberValue = event;
      if (!cardNumberValue.empty  && !cardNumberValue.error) {
       let el = window.document.getElementById('card-expiry')
        el?.classList.add('active-field');
        el?.classList.remove('has-error');
      } if (cardNumberValue.complete && !cardNumberValue.error){
        let el = window.document.getElementById('card-expiry')
        el?.classList.remove('active-field');
        el?.classList.remove('has-error');
      }
      if ( cardNumberValue.error){
        let el = window.document.getElementById('card-expiry')
        el?.classList.add('has-error');
      }
    });
    this.cardCvc.on('change', (event) => {
      const cardNumberValue = event;
      if (!cardNumberValue.empty && !cardNumberValue.error ) {
       let el = window.document.getElementById('card-cvc')
        el?.classList.add('active-field');
        el?.classList.remove('has-error');
      } if (cardNumberValue.complete && !cardNumberValue.error){
        let el = window.document.getElementById('card-cvc')
        el?.classList.remove('active-field');
        el?.classList.remove('has-error');
      }
      if ( cardNumberValue.invalid){
        let el = window.document.getElementById('card-cvc')
        el?.classList.add('has-error');
      }
    });
  },
  beforeDestroy () {
    this.cardNumber?.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
    this.updating = false;
    eventBus.$off('submitCart')
  },
  methods: {
    async createPaymentMethod () {
      this.updating = true;
      const {paymentMethod, error} = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardNumber,
        billing_details: {
          name: '',
        },
      });
      if (error) {
        // handle error here
        if(!this.payBalanceOn) {
          this.cartStore.$patch((state) => {
            state.payNowLoading = false;
          });
          this.$emit('cardError', error.message)
        }
        document.getElementById('card-error').innerHTML = error.message;
        this.updating = false;
        return;
      }
      const custPaymentID = await this.customerPaymentId();
      if(this.showAddCard === true || this.payBalanceOn) {
        this.addNewPaymentMethod(paymentMethod.id, custPaymentID.customer.payment.id)
        if(this.payBalanceOn) {
          const cardInfo = await this.customerAddCard(paymentMethod.id)
          await this.customerSetDefaultCard(paymentMethod.id);
        }
      }
      if(!this.payBalanceOn) {
        eventBus.$emit('paymentMethodId', paymentMethod.id);
      }
      else {
        eventBus.$emit('submitPayBalance', paymentMethod.id);
      }
    },

    async addNewPaymentMethod (token, custId){
      const setupIntent = await this.stripe?.setupIntents?.create ({
        customer: custId,
        payment_method: token,
        payment_method_types: ['card'],
        usage: 'off_session'
      });
      this.updating = false;
    }

  },
  setup() {
    const { cardDetailsFilled, $patch } = useCartStore();
    const cartStore = useCartStore();
    const { customerCards, customerPaymentId, customerSetDefaultCard, customerAddCard } = useUser();
    const name = ref('');
    const token = ref(null);
    const cardNumber = ref(null);
    const cardExpiry = ref(null);
    const cardCvc = ref(null);
    const paymentMethod= ref(null);
    return {
      cardDetailsFilled,
      token,
      cardNumber,
      cardExpiry,
      cardCvc,
      paymentMethod,
      name,
      customerPaymentId,
      cartStore,
      customerAddCard,
      customerSetDefaultCard
    }
  },
})
</script>

<style scoped>
#custom-button {
  height: 30px;
  width: 150px;
  padding: 5px 10px;
  border-radius: 25px;
  margin: auto;
  margin-right: 0;
  color: #FFFFFF;
}

#card-error {
  color: red;
  margin-bottom: 15px;
}
/* #card-number, #card-expiry, #card-cvc  {
  height: 50px;
  width: 225px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
} */
#card-number, #card-expiry, #card-cvc{
      background: #FFFFFF;
      border: 1px solid #DAE7EA;
      border-radius: 10px;
      max-height: 60px;
      color: #6E768C;
      font-weight: 500;
      font-size: 18px;
      padding: 20px;
      
      width: 100%;
      display: block;
      margin-bottom: 30px;
    }
.exp-cvc-col {
  display: flex;
  column-gap: 20px;
}
@media screen and (max-width: 767px) {
  #card-number {
    max-width: 95%;
  }
}
#card-number:hover , #card-expiry:hover , #card-cvc:hover{
  border: 1px solid #9BA5BE;

}
#card-number:focus-visible , #card-expiry:focus-visible , #card-cvc:focus-visible{
  border: 1.5px solid #04AAB8!important;
}
.active-field{
  border: 1.5px solid #04AAB8;

}
.has-error{
  border: 1px solid #e2184a!important;
  position: relative;
}
.has-error:after{
    content: "";
    position: absolute;
    right: 14px;
    top: 18px;
    height: 32px;
    width: 32px;
    background-image: url(/icons/alertRed.svg);
}
.pay-balance-card {
  margin-right: 30px !important;
  margin-bottom: 30px !important;
}
.add-new-card {
  color: #132528;
  font-weight: 700;
  text-align: left;
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: 15px;
}
</style>
