


























import { defineComponent } from "@vue/composition-api";
import {computed, ref, watch, onMounted} from "@nuxtjs/composition-api";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import Icon from "~/almarwan/components/Icon.vue";
import {SfButton, SfCheckbox, SfInput, SfSelect, SfTextarea} from "@storefront-ui/vue";
import {Countries, Country, useCountrySearch, UseCountrySearchParams} from "~/composables";
import addressGetter from "~/modules/customer/getters/addressGetter";
import LocalDelivery from "~/modules/checkout/pages/Checkout/Delivery/LocalDelivery.vue";
import PickUp from "~/modules/checkout/pages/Checkout/Delivery/PickUp.vue";
import ArrangeMyShipping from "~/modules/checkout/pages/Checkout/Delivery/ArrangeMyShipping.vue";
import ShippingDetails from '~/modules/checkout/pages/Checkout/Shipping.vue'
import {eventBus} from "~/almarwan/helpers/EventBus";
import ShippingDimensions from "~/modules/checkout/pages/Checkout/Delivery/ShippingDimension.vue";

export default defineComponent({
  name: 'ShippingMethods',
  components: {
    ArrangeMyShipping,
    PickUp,
    LocalDelivery,
    Icon,
    SfInput,
    SfButton,
    SfSelect,
    SfCheckbox,
    SfTextarea,
    ValidationProvider,
    ValidationObserver,
    ShippingDetails,
    ShippingDimensions
  },
  props: {
    parentCheck: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      required: true
    },
    selectedMethod: {
      type: String,
      default: ''
    },
    addedAddress: {
      type: [Array, Object],
      required: true
    }
  },
  setup (props, {emit}) {
    const { load: loadCountries, search: searchCountry } = useCountrySearch();
    const methodBody = ref(false)
    const loading = ref(false)
    const methodData = ref({
      pickUp: true,
      deliver: false
    })
    const countries = ref<Countries[]>([]);
    const countriesList = computed(() => addressGetter.countriesList(countries.value));
    const country = ref<Country | null>(null);
    // const regionInformation = computed(() => addressGetter.regionList(country.value));
    const isMobile = ref(false);
    const updateCountry = async (params: UseCountrySearchParams) => {
      country.value = await searchCountry(params);
    };
    const openPickUpMethodBody = () => {
      methodBody.value = true
      methodData.value.pickUp = true
      methodData.value.deliver = false
      if(isMobile)
        window.scrollTo(0, window.scrollY + 450)
    }
    const openDeliverMethodBody = () => {
      methodBody.value = true
      methodData.value.pickUp = false
      methodData.value.deliver = true
      if(isMobile)
        window.scrollTo(0, window.scrollY + 450)
    }
    const selectedAddress = (data) => {
      emit('dataForShippingMethod', { data })
    }
    const getCountryList = () => {
      if(props.product?.shippable_countries?.length) {
        return true
      }
      else {
        return false
      }
    }
    onMounted(async () => {
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        isMobile.value = true;
      }
      openPickUpMethodBody()
      if(props.selectedMethod == 'pickUp') {
        methodBody.value = true
        methodData.value.pickUp = true
        methodData.value.deliver = false
      }
      else if(props.selectedMethod == 'delivery') {
        methodBody.value = true
        methodData.value.pickUp = false
        methodData.value.deliver = true
      }
    })

    return {
      selectedAddress,
      updateCountry,
      countriesList,
      loading,
      openPickUpMethodBody,
      openDeliverMethodBody,
      methodData,
      methodBody,
      getCountryList
    }
  }
})
