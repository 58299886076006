



















import {defineComponent, onBeforeUnmount, PropType} from '@vue/composition-api'
import Product from "~/modules/checkout/pages/Checkout/Delivery/Product.vue";
import {CartItemInterface} from "~/modules/GraphQL/types";
import {computed, onMounted, ref, watch} from "@nuxtjs/composition-api";
import useShipping from "~/modules/checkout/composables/useShipping";
import useShippingProvider from "~/modules/checkout/composables/useShippingProvider";
import {useCartStore} from "~/modules/checkout/stores/cart";
import {eventBus} from "~/almarwan/helpers/EventBus";
import {useUiNotification} from "~/composables";

export default defineComponent({
  name: 'Delivery',
  components: {
    Product
  },
  props: {
    products: {
      type: Array as PropType<CartItemInterface[]>,
      default: 0,
    },
    firstItemSku: {
      type: String,
      default: ''
    },
    itemAddresses: {
      type: Array,
      default: []
    }
  },
  setup(props, { emit }) {
    const { shippingAddressSave } = useShipping();
    const { send: sendNotification } = useUiNotification();
    const { shippingMethodSave } = useShippingProvider();
    const { $patch } = useCartStore();
    const shippingMethodsForAddresses = ref([])
    const productShippingAddresses = ref([])
    const sameProductShippingAddressesForAll = ref([])
    const productToShow = ref([])
    const accordions = ref( [])
    const makeOfferArr = ref([])
    const financeProdArr = ref([])
    const filteredProducts = ref([])
    const itemNotToDisable = ref( null)
    const sameShippingForAllCheck = ref(false)
    const emailAdded = ref(false)

    const productsObjects = () => {
      let allProducts = props.products.map((prod, index) => {
        // @ts-ignore
        if(prod.custom_option && prod.custom_option.reserve_makeoffer > 0) {
          makeOfferArr.value.push(prod.product.sku)
        }
        // @ts-ignore
        if(prod.custom_option && prod.custom_option.reserve_finance > 0) {
          financeProdArr.value.push(prod.product.sku)
        }
        if (prod.quantity === 1) {
          let newProductObject = prod.product
          newProductObject['item_id'] = Number(atob(prod.uid))
          newProductObject['number'] = index
          return newProductObject
        } else {
          let repeatItems = Array.from({ length: prod.quantity }, () => ({ ...prod.product }));
          return repeatItems.map((item) => {
            item['item_id'] = prod.uid
            return item
          })
        }
      }).flat()

      if (allProducts.length > 0) {
        productToShow.value = allProducts.map((item, index) => {
          item['number'] = index++
        })
      }
      productToShow.value =  allProducts
      $patch((state) => {
        state.cartProductQuantity = productToShow.value.length === 0 ? props.products.length : productToShow.value.length
      })
      accordionData()
    }

    const accordionData = () => {
      accordions.value = productToShow.value.map((product) => {
        return { product: product.number, isOpen: false }
      })
    }

    const updateAccordionChange = (accordion) => {
      accordions.value = accordions.value.map((accord) => {
        if (accord.product === accordion.product) {
          accord.isOpen = accordion.isOpen
          return accord
        } else {
          accord.isOpen = false
          return accord
        }
      })
      document.documentElement.scrollIntoView({ behavior: "smooth" })
    }

    const collectProductsAddresses = async ({address}) => {
      if (address.sameForAll) {
        sameShippingForAllCheck.value = address.sameForAll
        await setShippingMethodSameForAllProducts(address)
        await disableItemsWithSameAddress(address)
      } else {
        itemNotToDisable.value = null
        sameShippingForAllCheck.value = false
      }
      let index = productShippingAddresses.value.findIndex(sAddress => sAddress.number === address.number)
      if (index !== -1) {
        productShippingAddresses.value[index] = address
      } else {
        productShippingAddresses.value.push(address)
      }
    }
    const makeOfferFilteredProducts = () => {
      filteredProducts.value = productToShow.value?.filter((item) => !makeOfferArr.value?.includes(item.sku))
    }
    const setShippingMethodSameForAllProducts = (address) => {
      const itemLengthRepeat = productToShow.value.length
      // Loop through productToShow.value to get the item_id attribute
      for (let i = 0; i < itemLengthRepeat; i++) {
        const itemId = productToShow.value[i].item_id
        // Create a new address object for this item and update the item_id attribute
        const addressWithItemId = {
          ...address, // Copy over all existing properties from the original address object
          item_id: itemId // Update the item_id property with the current item's ID
        }
        // Add this address object to the sameProductShippingAddressesForAll.value array for each item
        sameProductShippingAddressesForAll.value.push(addressWithItemId)
      }
      const updateSameProductShippingAddressesForAll = sameProductShippingAddressesForAll.value.filter((address) => {
        if (filteredProducts.value.includes(address.item_id)) {
          address.customer_address_id = 0;

          const index = sameProductShippingAddressesForAll.value.indexOf(address);
          sameProductShippingAddressesForAll.value[index] = address;
        }
      })
      setTimeout(() => {
        emit('itemValidationData', { addresses: sameProductShippingAddressesForAll.value.length, products: productToShow.value.length})
      }, 200)
    }

    const disableItemsWithSameAddress = (currentItemAddress) => {
      itemNotToDisable.value = currentItemAddress.number
    }
    const removeDuplicates = (arr) =>  {
      const ids = [];

      return arr.filter((obj) => {
        if (!ids.includes(obj.customer_address_id)) {
          ids.push(obj.customer_address_id);
          return true;
        }
        return false;
      })
    }

    const getDataForShippingMethodCall = (shippingMethods) => {
      let mthodsArr = ['marwanrate_marwanrate', 'marwanrate_tbc'];
      let notAllowShippingMethod = false;
      const uniqueMethods = removeDuplicates(productShippingAddresses.value)
      for (const address of shippingMethods) {
        const methods = [];
        for (const method of uniqueMethods) {
          if (method.customer_address_id === address.customer_address_id) {
            if(address.lastname !== "Head office") {
              const selectedMethod = address.available_shipping_methods?.find((shipping) => {
                return mthodsArr.includes(shipping.carrier_code + '_' + shipping.method_code)
              });
              if (selectedMethod) {
                methods.push({
                  address_id: address.id,
                  shipping_method: `${selectedMethod.carrier_code}_${selectedMethod.method_code}`
                });
              }
            }
            else {
              methods.push({
                address_id: address.id,
                shipping_method: `flatrate_flatrate`
              });
            }
          }
        }

        if (methods.length > 0) {
          shippingMethodsForAddresses.value.push(methods);
        }
        else {
          sendNotification({
            id: Symbol('user_updated'),
            message: 'The selected shipping method is not avaliable.',
            type: 'warning',
            icon: 'error',
            persist: false,
            title: 'User Account',
          });
          notAllowShippingMethod = true
          $patch((state) => {
            state.checkShippingMethod = true
          })
        }
      }
      if(!notAllowShippingMethod) {
        shippingMethodsForAddresses.value = shippingMethodsForAddresses.value.flat();
        sendShippingMethodApiCall(shippingMethodsForAddresses.value)
      }
    }

    const sendShippingMethodApiCall = async (shippingMethods) => {
      const shData = await shippingMethodSave({ shippingMethod: shippingMethods });
      const result = Boolean(shippingMethods.find((method) => method.shipping_method === 'marwanrate'));
      $patch((state)=>{
        state.shippingAdressFilled = true;
        if(shData !== null || typeof shData !== 'undefined') {
          state.totalShippingAmount = shData
        }
        state.allAddressesArePickup = result
      })
    }

    const proceedCheckout = async () => {
      let dataToSend
      if (sameShippingForAllCheck.value) {
        dataToSend = sameProductShippingAddressesForAll.value.map(({number, method, sameForAll, ...rest}) => rest)
        $patch((state) => {
          state.multiShippingAddresses = false;
        })
      } else {
        dataToSend = productShippingAddresses.value.map(({number, method, sameForAll, ...rest}) => rest);
        if (dataToSend.length > 1) {
          $patch((state) => {
            state.multiShippingAddresses = true;
          })
        }
      }
      await shippingAddressSave({ shippingDetails: dataToSend }).then(async (res) => {
        if (res) getDataForShippingMethodCall(res)
      });
    }

    const getAddressAdded = (itemId) => {
      let selAddr = {}
      props.itemAddresses?.forEach((item, index) => {
        //@ts-ignore
        if(item.cart_items_v2[0].id === itemId.toString()) {
          selAddr =  item
        }
      })
      return selAddr
    }

    watch(productShippingAddresses.value, () => {
      emit('itemValidationData', { addresses: productShippingAddresses.value.length, products: productToShow.value.length})
    })

    onMounted(()=> {
      productsObjects()
      eventBus.$on('emailValidation', (data) => {
        emailAdded.value = data
      })
      eventBus.$on('proceedToPayment', () => {
        if (sameProductShippingAddressesForAll.value.length === productToShow.value.length) {
          proceedCheckout()
        } else if (productShippingAddresses.value.length === productToShow.value.length) {
          proceedCheckout()
        } else {
          sendNotification({
            id: Symbol('user_updated'),
            message: 'Please select shipping for all items',
            type: 'danger',
            icon: 'error',
            persist: false,
            title: 'User Account',
          });
        }
      })
      makeOfferFilteredProducts()
    })
    onBeforeUnmount(() => {
      eventBus.$off('proceedToPayment');
    })

    return {
      itemNotToDisable,
      updateAccordionChange,
      accordions,
      productToShow,
      productsObjects,
      proceedCheckout,
      collectProductsAddresses,
      makeOfferArr,
      financeProdArr,
      getAddressAdded
    }
  },
})
