<template>
  <div class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
    <div class="bg-white p-6 rounded-lg shadow-lg w-96">
      <h2 class="text-xl font-mont-semibold mb-4">{{$t("popupTitle")}}</h2>
      <p class="mb-6">{{$t('checkoutMessage')}}</p>
      <div class="flex justify-end space-x-4 gap-2">
        <button @click="onCancel" class="bg-color-light-orange text-color-orange hover:bg-gray-100 font-mont-semibold py-2 px-4 border-[2px] border-color-orange rounded-full">
          {{$t("Cancel")}}
        </button>
        <button @click="onOk" class="bg-color-orange hover:bg-color-primary-200 text-white font-mont-semibold py-2 px-4 rounded-full">
          {{$t('confirmBtn')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    onOk: {
      type: Function,
      required: true
    },
    onCancel: {
      type: Function,
      required: true
    }
  }
});
</script>
