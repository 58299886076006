
























































































































































































import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  useRoute,
  useRouter,
  useContext
} from '@nuxtjs/composition-api';
import useCart from '~/modules/checkout/composables/useCart';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { eventBus } from "@/almarwan/helpers/EventBus"
import {useCartStore} from "~/modules/checkout/stores/cart";
import { useUiState } from '~/composables/useUiState';
import { getCategory } from '~/modules/catalog/product/getters/productGetters';
import InformationPillVue from "~/modules/catalog/category/components/views/InformationPill.vue";
import {removeItem} from "~/helpers/asyncLocalStorage";
import useUiHelpers from "../../../../composables/useUiHelpers";
import useUserOrder from "~/modules/customer/composables/useUserOrder";
import {useUiNotification} from "~/composables";

export default defineComponent({
  data() {
    return {
      disabled: true,
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    appliedTaxValue: {
      type: Number,
      default: 0,
    },
    payBalanceOn: {
      type: Boolean,
      default: false
    },
    payBalanceOrder: {
      type: String,
      default: null
    },
    payBalanceItem: {
      type: Object,
      default: null
    },
    payBalanceDue: {
      type: Number,
      default: 0,
    },
    payBalanceId: {
      type: String,
      default: null
    },
    payBalanceTnC: {
      type: Boolean,
      default: false
    },
    customerSelectedMethod: {
      type: String,
      default: null
    }
  },
  components: {
    InformationPillVue
  },
  beforeDestroy() {
    eventBus.$off('termsChecked')
  },
  setup(props, { emit }) {
    const { cart, load, createQuotation, setCart, error } = useCart();
    const { numberWithCommas } = useUiHelpers()
    const { app } = useContext();
    const { $patch, totalShippingAmount, payNowLoading } = useCartStore();
    const { offerAmount, isReserveProd, prodInfo } = useUiState();
    const store = useCartStore();
    const { payForOrder, errorPayOrder } = useUserOrder();
    const { send: sendNotification } = useUiNotification();
    const products = computed(() => cartGetters.getItems(cart.value));
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const productLoaded = ref(false);
    const shippingTotal = ref(0);
    const cartProdsArr = ref([]);
    const enableSubmit = ref(false);
    const enablePayBalance = ref(true);
    const disabled = ref(true);
    const expand = ref(false);
    const useSavedCard = ref(false);
    const savedCardId = ref('');
    const quoteLoading = ref(false);
    const marwanRateZero = ref(false);
    const mrwanrateProdIncart = ref(false);
    const payBalanceLoading = ref(false);
    //const loading = ref(false);
    store.$subscribe(async (mutation, state) => {
      if(state.billingAddressFilled && state.shippingAdressFilled && state.tAndcChecked) {
        enableSubmit.value = true;
      } else {
        enableSubmit.value = false;
      }
      shippingTotal.value = state.totalShippingAmount
    });
    const getCategoryName = (prod) => {
      var prodCat = prod?.categories?.filter((cat) => cat.level === 3);
      return prodCat[0]?.name;
    };
    const cartHasFinanceProduct = () => {
      let finProd = false
      cartProdsArr.value?.forEach(element => {
        if(element.custom_option?.reserve_finance > 0) {
          finProd = true
        }
      });
      return finProd
    }

    const submitCart = () => {
      eventBus.$emit('startLoading', true)
      enableSubmit.value = false;
      $patch((state) => {
        state.payNowLoading = true;
      });
      if(cartHasFinanceProduct()){
        store.financeProductOnCart = true;
      }
      if(useSavedCard.value) {
        emit('paymentMethodId', useSavedCard.value);
      } else {
        eventBus.$emit('submitCart', 'buynow')
        emit('submitCart', 'no')
      }
      setTimeout(() => {
        if (store.billingAddressFilled && store.shippingAdressFilled && store.tAndcChecked) {
          enableSubmit.value = true;
        } else {
          enableSubmit.value = false;
        }
      }, 10000)
    }

    const convertToQuote = async () => {
      quoteLoading.value = true;
      let quote = await createQuotation()
      if (quote) {
        setCart(null);
        app.$vsf.$magento.config.state.removeCartId();
        await removeItem('checkout');
        emit('orderProcessed', { status: 'succeeded', from: 'quote', quote: quote, orderError: null})
        document.documentElement.scrollIntoView({ behavior: "smooth" })
      }
      quoteLoading.value = false;
    }

    const warrantyCheck = (val) => {
      if(val.length){
        return val.filter(item => item.label === "Warranty");
      }
      else {
        return false
      }
    }
    const getWarrantyLabel = (options) => {
      let warrantyCheck = options.filter(item => item.label === "Warranty").map(vlas => vlas.values[0].label)
      if(warrantyCheck.length){
        return options.filter(item => item.label === "Warranty").map(vlas => vlas.values[0].label)[0];
      }
      else {
        return '1 Year'
      }

    }
    const installationCheck = (val) => {
      let stat = false;
      val?.forEach(element => {
        if(element.label === 'Installation') {
          stat = true
          return
        }
      });
      return stat;
    }
    const getInstallationValue = (options) => {
      let checkedVal = options.filter(item => item.label === "Installation").map(vlas => vlas.values[0].label)[0];
      return checkedVal
    }
    const expandTotalDiv = () => {
      if (!app.$device.isDesktop) {
        expand.value=!expand.value
        const bodyElement = document.querySelector('body');
        if(expand.value) {
          bodyElement.classList.add('overflow--hidden')
        }
        else {
          bodyElement.classList.remove('overflow--hidden')
        }
      }
    }
    const getFilteredAddress = (pid) => {
      return cart.value?.shipping_addresses?.filter(address => {
        return address.cart_items_v2.some(item => item.id === pid);
      });
    }
    const getShippingTotal = (pid) => {
      let currItemAddr = getFilteredAddress(pid)
      if(currItemAddr?.[0]?.selected_shipping_method?.carrier_code === "marwanrate" && currItemAddr?.[0]?.selected_shipping_method?.amount?.value == 0) {
        marwanRateZero.value = true ;
        mrwanrateProdIncart.value = true;
      }
      else {
        marwanRateZero.value = false
      }
      return currItemAddr?.[0]?.selected_shipping_method?.amount?.value
    }
    const payRemainingBalance = async () =>  {
      payBalanceLoading.value = true
      if(useSavedCard.value || props.customerSelectedMethod === 'banktransfer') {
        let decodeId = Number(atob(props.payBalanceId))
        if(decodeId) {
          await payForOrder(decodeId).then(() => {
            if (errorPayOrder.value.order === null) {
              eventBus.$emit('payForOrderSuccess', true)
            }
          })
          if (errorPayOrder.value.order && errorPayOrder.value.order[0].message) {
            eventBus.$emit('payForOrderSuccess', false)
            sendNotification({
              id: Symbol('product_added_to_cart_from_wishlist'),
              message: errorPayOrder.value?.order[0]?.message || 'Payment failed due to some issue, Please try again',
              type: 'danger',
              icon: 'cross',
              persist: false,
              title: 'Upcoming payment',
            });
          }
        }
        payBalanceLoading.value = false
      }
      else {
        eventBus.$emit('submitCart', 'payBalance')
      }
    }
    onMounted(async () => {
      if(!props.payBalanceOn) {
        await load();
        cartProdsArr.value = cart.value?.items;
        store.onCheckoutPaymentPage = true
      }
      eventBus.$on('termsChecked', (val) => {
        disabled.value = !val;
      });
      eventBus.$on('savedCardSlelected', (card) => {
        if(card) {
          useSavedCard.value = true;
          savedCardId.value = card.id
        }
      });
      //store.onCheckoutPaymentPage = true
    });
    onBeforeUnmount(async() => {
      $patch((state)=>{
        state.shippingAdressFilled = false;
        state.billingAddressFilled = false;
        state.tAndcChecked = false;
      })
      eventBus.$off('savedCardSlelected');
      eventBus.$off('termsChecked')
    });

    return {
      totals,
      convertToQuote,
      expand,
      disabled,
      numberWithCommas,
      submitCart,
      shippingTotal,
      products,
      cart,
      productLoaded,
      cartProdsArr,
      enableSubmit,
      //loading,
      offerAmount,
      isReserveProd,
      prodInfo,
      getCategoryName,
      totalShippingAmount,
      warrantyCheck,
      getWarrantyLabel,
      installationCheck,
      getInstallationValue,
      payNowLoading,
      expandTotalDiv,
      quoteLoading,
      getShippingTotal,
      marwanRateZero,
      mrwanrateProdIncart,
      payRemainingBalance,
      enablePayBalance,
      payBalanceLoading
    }
  },
})
