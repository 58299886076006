








































































import { SfSteps } from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  ref,
  useRoute,
  useRouter,
  useContext,
  onMounted,
  watch,
  onBeforeUnmount
} from '@nuxtjs/composition-api';
import Icon from '@/almarwan/components/Icon.vue'
import cartGetters from '~/modules/checkout/getters/cartGetters';
import useCart from '~/modules/checkout/composables/useCart';
import CartPreview from '~/modules/checkout/components/CartPreview.vue';
import PaymentPage from '~/modules/checkout/pages/Checkout/paymentPage.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import ShoppingCart from '~/modules/checkout/pages/Checkout/ShoppingCart/ShoppingCart.vue'
import { SfLoader } from '@storefront-ui/vue';
import { useUser } from '~/modules/customer/composables/useUser';
import Delivery from "~/modules/checkout/pages/Checkout/Delivery/Delivery.vue";
import CheckoutHeader from "~/modules/checkout/pages/Checkout/Steps.vue";
import TotalSection from '~/modules/checkout/pages/Checkout/ShoppingCart/TotalSection.vue';
import {eventBus} from "~/almarwan/helpers/EventBus";
import ReceiptPage from '~/modules/checkout/pages/Checkout/ShoppingCart/ReceiptPage.vue';
import {useCartStore} from "~/modules/checkout/stores/cart";
import {useUiNotification, useUiState, useCurrency, useMagentoConfiguration} from "~/composables";
import CurrencyConfirmModal from '../components/CurrencyConfirmModal.vue';
import { getOperationAST } from 'graphql';

export default defineComponent({
  name: 'CheckoutPage',
  components: {
    CheckoutHeader,
    Delivery,
    SfSteps,
    CartPreview,
    PaymentPage,
    SfLoader,
    SkeletonLoader,
    ShoppingCart,
    TotalSection,
    Icon,
    ReceiptPage,
    CurrencyConfirmModal
  },
  data () {
    return {
      breadcrumbs: [
        {
          link: '/Checkout',
          text: 'Shopping Cart'
        },
        {
          link: '/Checkout',
          text: 'Checkout'
        }
      ],
    }
  },
  created () {
    if (process.browser){
      window.addEventListener('mousemove', this.handleMouseMove);
    }
  },
  destroyed () {
    if (process.browser){
      window.removeEventListener('mousemove', this.handleMouseMove);
    }
  },
  methods: {
    handleMouseMove (event) {
      if(!this.isAuthenticated) {
        this.$router.push('/');
      }
    }
  },
  mounted() {
    const bodyStyles = `direction: ltr;`;
    if (document) {
      document.body.style.cssText = this.$i18n.locale !== 'ar' ? bodyStyles: '';
    }
  },
  setup() {
    const { send: sendNotification } = useUiNotification();
    const route = useRoute();
    const { app } = useContext();
    const { path } = route.value;
    const router = useRouter();
    const store = useCartStore();
    const {$patch} = useCartStore();
    const { isAuthenticated } = useUser();
    const { cart, load, clear } = useCart();
    const { toggleLoginModal, productInCart } = useUiState();
    const { change: changeCurrency } = useCurrency();
    const {
      selectedCurrency
    } = useMagentoConfiguration();
    const {app: { i18n }} = useContext();
    const products = computed(() => cartGetters.getItems(cart.value));
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const currentStep = computed(() => path.split('/').pop());
    const orderPlacing = ref(false);
    const loadPayment = ref(false);
    const loading = ref(false);
    const successType = ref(null);
    const orderStatus = ref(null);
    const orderId = ref(null);
    const error = ref([]);
    const quoteId = ref(null);
    const addressLength = ref(0);
    const productLength = ref(0);
    const activeStep = ref(0);
    const cartItemCount = ref(0);
    const firstItemSku = ref('')
    const buyNowCount = ref(0);
    const reserveCount = ref(0);
    const buyNowTotal = ref(0);
    const reserveTotal = ref(0);
    const itemAddresses = ref(null);
    const appliedTaxValue = ref(0);
    const payBalanceOn = ref(false);
    const payBalanceOrder = ref(null);
    const showCurrencyMsg = ref(false);
    const baseCurr = process.env.BASE_CURRENCY;
    const handleOk = () => {
      showCurrencyMsg.value = false;
      changeCurrency({id: baseCurr})
    };

    const handleCancel = () => {
      showCurrencyMsg.value = false;
      router.go(-1);
    };
    const checkoutSteps = ref([{
      title: 'Shopping Cart',
      name: 'cart',
      id: 1
    },{
      title: 'Shipping Information',
      name: 'delivery',
      id: 2
    }, {
      title: 'Payment Details',
      name: 'payment',
      id: 3
    }, {
      title: 'Receipt',
      name: 'receipt',
      id: 4
    }])
    const proceedCheckout = () => {
      activeStep.value++
    }
    const proceedFromDeliveryCheckout = () => {
      if (productLength.value !== 0 && addressLength.value !== 0 && addressLength.value === productLength.value) {
        orderPlacing.value = true
        eventBus.$emit('proceedToPayment')
        setTimeout(() => {
          activeStep.value++
          document.documentElement.scrollIntoView({ behavior: "smooth" })
        }, 200)
      } else {
        orderPlacing.value = false
        sendNotification({
          id: Symbol('user_updated'),
          message: 'Please select shipping for all items',
          type: 'danger',
          icon: 'error',
          persist: false,
          title: 'User Account',
        });
      }
    }
    const setNewStep = (val) => {
      activeStep.value = val
    }
    const orderProcessed2 = ({status, from, quote, orderError, orderNumber}) => {
      if(status === 'succeeded') {
        activeStep.value = 3
        successType.value = from
        quoteId.value = quote
        orderStatus.value = status
        orderId.value = orderNumber
        error.value = []
      }
      if(status === 'failed') {
        error.value = orderError
        successType.value = from
        quoteId.value = quote
        orderStatus.value = status
        activeStep.value = 3
      }
    }
    const orderProcessed = ({status, from, quote, orderError, orderNumber}) => {
      if(status === 'succeeded') {
        activeStep.value++
        successType.value = from
        quoteId.value = quote
        orderStatus.value = status
        orderId.value = orderNumber
        error.value = []
      }
      if(status === 'failed') {
        error.value = orderError
        successType.value = from
        quoteId.value = quote
        orderStatus.value = status
        activeStep.value++
      }
    }
    const getReservationItems = () => {
      //@ts-ignore
      const reserveProds = products.value?.filter((item) => item?.custom_option?.reserve_makeoffer !==0 || item?.custom_option?.reserve_finance == 1)
      //@ts-ignore
      const buyNowProds = products.value?.filter((item) => item?.custom_option?.reserve_makeoffer ==0 && item?.custom_option?.reserve_finance == 0)
      if(reserveProds.length && buyNowProds.length) {
        reserveCount.value = reserveProds.length;
        buyNowCount.value = products.value.length - reserveCount.value
        reserveTotal.value = getItemTypeTotals(reserveProds)
        buyNowTotal.value = getItemTypeTotals(buyNowProds)
      }
      else if(reserveProds.length == 0 && buyNowProds.length) {
        buyNowCount.value = products.value.length
        buyNowTotal.value = getItemTypeTotals(buyNowProds)
        reserveTotal.value = 0
        reserveCount.value = 0
      }
      else {
        reserveCount.value = reserveProds.length;
        reserveTotal.value = getItemTypeTotals(reserveProds)
        buyNowTotal.value = 0
        buyNowCount.value = 0
      }
    }
    const getItemTypeTotals = (itemsArr) => {
      let total = 0
      itemsArr.forEach(element => {
        if(element.custom_option?.reserve_order!==null) {
          total = total + element.custom_option?.reserve_order?.value?.value
        }
        else if(element.custom_option?.reserve_finance == 1) {
          total = total + element.product?.reserve_fee
        }
        else {
          total = total + element.prices?.row_total?.value
        }
      });
      return total
    }
    onMounted(async () => {
      if(route.value.query.from === 'orders') {
        payBalanceOn.value = true
        payBalanceOrder.value = route.value.query.id
        store.payBalanceCart = true
        loading.value = true
        activeStep.value = 2
      }
      else {
        await load();
        setTimeout(()=>{
          loading.value = true
        },600)
      }
      if (!isAuthenticated.value ) {
        await router.push(app.localePath('/'));
        toggleLoginModal()
      }
      // @ts-ignore
      firstItemSku.value = products.value?.find(element => element?.custom_option && element?.custom_option?.reserve_makeoffer === 0)?.product?.sku;
      store.onCheckoutPaymentPage = false
      eventBus.$on('orderProcessed',({status, from, quote, orderError, orderNumber}) => {
        orderProcessed2({status, from, quote, orderError, orderNumber})
      })
      eventBus.$on('payForOrderSuccess',(val) => {
        if(val) {
          activeStep.value = 3
          successType.value = 'payBalance'
          orderStatus.value = 'succeeded'
          error.value = []
          payBalanceOn.value = false
          store.payBalanceCart = false
        }
      } )
      if(selectedCurrency.value !== baseCurr) {
        showCurrencyMsg.value = true
      }
    });
    onBeforeUnmount(() => {
      $patch((state)=>{
        state.activeStep = 0;
        state.payBalanceCart = false;
      })
    })

    const proceedToCheckoutValidation = ({addresses, products}) => {
      addressLength.value = addresses
      productLength.value = products
    }

    watch(() => cart.value, () => {
      loadPayment.value = true;
      cartItemCount.value = cart.value?.items?.length
      itemAddresses.value = cart.value?.shipping_addresses?.length > 0 ? cart.value?.shipping_addresses : null
      appliedTaxValue.value = cart.value?.prices?.pay_now_tax?.value
      getReservationItems()
    });
    watch(() => activeStep.value, () => {
      $patch((state)=>{
        state.activeStep = activeStep.value
      })
      setTimeout(() => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      }, 500);

    });
    store.$subscribe(async (mutation, state) => {
      if(state.activeStep == 0) {
        setNewStep(0)
      }
    });

    return {
      orderId,
      orderPlacing,
      orderProcessed,
      error,
      orderStatus,
      addressLength,
      productLength,
      quoteId,
      successType,
      proceedToCheckoutValidation,
      proceedFromDeliveryCheckout,
      proceedCheckout,
      activeStep,
      checkoutSteps,
      cart,
      products,
      loading,
      load,
      clear,
      currentStep,
      isAuthenticated,
      loadPayment,
      totals,
      setNewStep,
      cartItemCount,
      firstItemSku,
      buyNowCount,
      reserveCount,
      buyNowTotal,
      reserveTotal,
      itemAddresses,
      appliedTaxValue,
      payBalanceOn,
      payBalanceOrder,
      showCurrencyMsg,
      handleOk,
      handleCancel,
      selectedCurrency,
      baseCurr
    };
  },
});
