


































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'EstimatorBox',
  props: {
    estimatedPrice: {
      type: Number,
      default: 0
    },
    selCityName: {
      type: String,
      default: '--'
    },
    estimatedDays: {
      type: String,
      default: '--'
    }
  },
  setup() {

  },
})
