


















































































































































import { defineComponent, ref, PropType } from '@vue/composition-api'
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import type { CartItemInterface } from '~/modules/GraphQL/types';
import Icon from '@/almarwan/components/Icon.vue';
import {eventBus} from "~/almarwan/helpers/EventBus";
import {useUiNotification} from "~/composables";
import useUiHelpers from "../../../../../composables/useUiHelpers";
import {removeItem} from "~/helpers/asyncLocalStorage";
import useCart from "~/modules/checkout/composables/useCart";

export default defineComponent({
  name: 'ShoppingCart',
  components: {
    SkeletonLoader,
    Icon
  },
  props: {
    activeStep: {
      type: Number,
      default: 0,
    },
    totals: {
      type: Object,
      required: true,
    },
    cartItemCount: {
      type: Number,
      default: 0,
    },
    reserveCount: {
      type: Number,
      default: 0
    },
    buyNowCount: {
      type: Number,
      default: 0
    },
    reserveTotal: {
      type: Number,
      default: 0
    },
    buyNowTotal: {
      type: Number,
      default: 0
    },
    appliedTaxValue: {
      type: Number,
      default: 0
    },
    selectedCurrency: {
      type: String,
      default: 'USD'
    }
  },
  setup(props, {emit}) {
    const expand = ref(false)
    const { numberWithCommas } = useUiHelpers()
    const { cart, load, createQuotation, setCart, error } = useCart();
    const nextStep = () => {
      emit('proceedCheckout');
    }
    const expandSlider = () => {
      expand.value=!expand.value
    }
    const proceedToPayment = () => {
      emit('triggerProceedButton')
    }
    const convertToQuote = async () => {
      let quote = await createQuotation()
      if (quote) {
        eventBus.$emit('orderProcessed', { status: 'succeeded', from: 'quote', quote: quote, orderError: null})
        document.documentElement.scrollIntoView({ behavior: "smooth" })
      }
    }
    return {
      convertToQuote,
      nextStep,
      expandSlider,
      expand,
      proceedToPayment,
      numberWithCommas
    }
  },
})
