



import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    content: {
      type: String,
      required: true,
      default: ''
    }
  },
  setup(props) {
  },
})
