




































































import { defineComponent } from "@vue/composition-api";
import {ref} from "@nuxtjs/composition-api";

export default defineComponent({
  name: 'ShippingDimensions',
  props: {
    product: {
      type: [Array, Object],
      required: true
    }
  },
  setup (props) {
    const  isMetricsListActive = ref(false);
    const toggleMetricsList = () => {
      isMetricsListActive.value = !isMetricsListActive.value
    }
    const showStat = ref('Show');
    const setShowStat = () => {
      if(showStat.value === 'Show'){
        showStat.value = 'Hide';
      }
      else {
        showStat.value = 'Show';
      }
    }
    return {
      toggleMetricsList,
      isMetricsListActive,
      showStat,
      setShowStat
    }
  }
})
