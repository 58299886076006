

































































































































































































































































import {
  SfInput,
  SfButton,
  SfSelect,
  SfCheckbox,
} from '@storefront-ui/vue';
import {toRef, computed, defineComponent, ref, watch, onBeforeMount, onMounted} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import userAddressesGetters from '~/modules/customer/getters/userAddressesGetters';
import type { TransformedCustomerAddress } from '~/modules/customer/composables/types';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import omitDeep from "omit-deep";
import addressGetter from "~/modules/customer/getters/addressGetter";
import {Country} from "~/modules/GraphQL/types";
import {Countries, useAddresses, useCountrySearch, UseCountrySearchParams, useUiNotification} from "~/composables";
import userGetters from "~/modules/customer/getters/userGetters";
import {SubmitEventPayload} from "~/modules/customer/types/form";
import {ProfileUpdateFormFields} from "~/modules/customer/pages/MyAccount/MyProfile/types";
import {eventBus} from "~/almarwan/helpers/EventBus";
import useShipping from '~/modules/checkout/composables/useShipping';

export default defineComponent({
  name: 'UserAddressDetails',
  components: {
    SfInput,
    SfButton,
    SfSelect,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    address: {
      type: Object as PropType<TransformedCustomerAddress>,
      required: false,
    },
    index: {
      type: Number,
      default: 0
    },
    isDefault: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const address = toRef(props, 'address');
    const { load: loadCountries, search: searchCountry } = useCountrySearch();
    const { load: loadAddress, update: updateAddress } = useAddresses();
    const { getCities } = useShipping();
    const form = ref<TransformedCustomerAddress | null>(null);
    const activeRow = ref('');
    const country = ref<Country | null>(null);
    const selCountryName = ref('');
    const selCityName = ref('');
    const isHovered = ref('');
    const countries = ref<Countries[]>([]);
    const countriesList = computed(() => addressGetter.countriesList(countries.value));
    const regionInformation = computed(() => addressGetter.regionList(country.value));
    const userAddress = computed(() => ({
      firstName: userAddressesGetters.getFirstName(address.value) || '',
      lastName: userAddressesGetters.getLastName(address.value) || '',
      street: userAddressesGetters.getStreetName(address.value) || '',
      streetNumber: userAddressesGetters.getApartmentNumber(address.value) || '',
      postalCode: userAddressesGetters.getPostCode(address.value) || '',
      city: userAddressesGetters.getCity(address.value) || '',
      province: userAddressesGetters.getProvince(address.value) || '',
      country: userAddressesGetters.getCountry(address.value) || '',
      telephone: userAddressesGetters.getPhone(address.value) || '',
      isDefault: userAddressesGetters.isDefault(address.value) || '',
      isDefaultShipping: userAddressesGetters.isDefaultShipping(address.value) || false,
      isDefaultBilling: userAddressesGetters.isDefaultBilling(address.value) || false
    }));
    const citiesList = ref([]);
    const isDefaultShippingText = computed(() => (userAddress.value.isDefaultShipping ? 'Default Shipping Address' : ''));

    const isDefaultBillingText = computed(() => (userAddress.value.isDefaultBilling ? 'Default Billing Address' : ''));
    const updateCountry = async (params: UseCountrySearchParams) => {
      country.value = await searchCountry(params);
      form.value.region = {
        // let region SfSelect know it should display initial state
        ...(regionInformation.value.length > 0 ? { region_id: null } : {}),
      };
      setCountryName();
      citiesList.value =  await getCities(country.value?.id, "")
    };
    const setCountryName = () => {
      let obj = countriesList.value.find(o => o.id === form.value.country_code);
      selCountryName.value = obj?.label;
    }
    onBeforeMount(async () => {
      countries.value = await loadCountries();
      if (props.address.country_code) {
        country.value = await searchCountry({ id: props.address.country_code });
      }
    });
    const { send: sendNotification } = useUiNotification();
    const submitForm = () => {
      const regionId = regionInformation.value.find((r) => r.abbreviation === form.value.region.region_code)?.id;
      if (regionId) {
        form.value.region.region_id = regionId;
      }
      const onComplete = () => {
        // form.value = userAddress.value
        sendNotification({
          id: Symbol('user_updated'),
          message: 'The user account data updated successfully!',
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'User Account',
        });
        // resetValidationFn();
      };
      const onError = (message: string) => {
        sendNotification({
          id: Symbol('user_updated'),
          message,
          type: 'danger',
          icon: 'cross',
          persist: false,
          title: 'User Account',
        });
      };
      const eventPayload : SubmitEventPayload<TransformedCustomerAddress> = { form: form.value, onComplete, onError };
      emit('submit', eventPayload);
    };
    const changeCity = (val) => {
      if(val) {
        let obj = citiesList.value.find(o => o.name === val);
        form.value.postcode = obj.id;
      }
    }
     const handleHover = (inputField) =>{
          isHovered.value = inputField

      }

    watch(() => props.address, () => {
      form.value = {
        id: props.address.id,
        apartment: props.address.apartment,
        city: props.address.city,
        country_code: props.address.country_code,
        firstname: props.address.firstname,
        lastname: props.address.lastname,
        postcode: props.address.postcode,
        region: {
          region_code: '',
          region_id: null,
          ...props.address.region,
        },
        street: props.address.street,
        telephone: props.address.telephone,
        default_shipping: props.address.default_shipping || false,
        default_billing: props.address.default_billing || false,
      } as TransformedCustomerAddress;
    }, { immediate: true });

    watch(() => form.value, () => {
      eventBus.$emit('updateFormCheckBox', form.value)
    }, { deep: true });
    watch(()=> countriesList.value, () => {
      if(countriesList.value.length)
        setCountryName();
    })
    onMounted(async () => {
      citiesList.value =  await getCities(form.value.country_code, "")
    })
    return {
      countriesList,
      updateCountry,
      submitForm,
      form,
      activeRow,
      userAddress,
      isDefaultShippingText,
      isDefaultBillingText,
      citiesList,
      selCountryName,
      changeCity,
      handleHover,
      isHovered
    };
  },
});
