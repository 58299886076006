<template>
  <div class="content-wrapper payment-methods">
    <div class="section-title">
      <p>Choose your Payment option</p>
    </div>
    <div class="payment-options">
      <div v-for="(method, index) in paymentMethods" :key="index">
        <div class="payment-page__box text--center cards-list" @click.prevent="selectMethod(method)" v-if="method.code === 'stripe_payments'" :class="{'active': activePaymentMethod === 'stripe_payments'}">
          <label for="html" :class="{'change-when-click': activePaymentMethod === 'stripe_payments'}">Pay by Credit/Debit Card</label>
          <div class="cc-options">
            <nuxt-picture loading="lazy" src="/images/global/Group4766.svg" alt="Payment Options for Credit or Debit cards" />
          </div>
        </div>
        <div class="payment-page__box text--center cards-list" @click.prevent="selectMethod(method)" v-if="method.code === 'banktransfer'" :class="{'active': activePaymentMethod === 'banktransfer'}">
          <label for="html" :class="{'change-when-click': activePaymentMethod === 'banktransfer'}">Pay with Wire Transfer</label>
          <div class="cc-options flex gap-2">
            <nuxt-picture loading="lazy" src="/icons/bank.svg" />
            <nuxt-picture loading="lazy" src="/icons/Group3698.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, watch } from "@nuxtjs/composition-api";

export default defineComponent({
  props: {
    paymentMethods: {
      type: Array,
      required: true
    },
    payBalancePaymentMethod: {
      type: String,
      default: null
    }
  },
  setup(props, { emit }) {
    const activePaymentMethod = ref('stripe_payments')
    const selectMethod = (method) => {
      activePaymentMethod.value = method.code
      emit('selectedMethod', method.code)
    }
    watch(() => props.payBalancePaymentMethod, () => {
      activePaymentMethod.value = props.payBalancePaymentMethod
    })

    return {
      selectMethod,
      activePaymentMethod
    }
   }
})
</script>
<style lang="scss" scoped>
.change-when-click {
  color: #6E768C !important;
  font-family: "Mont-semibold" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: 32px !important;
}

</style>
