

















































































































































































import {
  SfHeading,
  SfInput,
  SfButton,
  SfSelect,
  SfCheckbox,
} from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, min, digits, max, numeric } from 'vee-validate/dist/rules';
import {
  ref,
  computed,
  onMounted,
  useRouter,
  defineComponent,
  useContext,
} from '@nuxtjs/composition-api';
import { useUiNotification } from '~/composables';
import userBillingGetters from '~/modules/customer/getters/userBillingGetters';
import addressGetter from '~/modules/customer/getters/addressGetter';
import { useCountrySearch } from '~/composables';

import useShipping from '~/modules/checkout/composables/useShipping';
import useBilling from '~/modules/checkout/composables/useBilling';
import { useUser } from '~/modules/customer/composables/useUser';
import { useUserAddress } from '~/modules/customer/composables/useUserAddress';
import UserAddressDetails from '~/components/UserAddressDetails.vue';
import useCart from '~/modules/checkout/composables/useCart';
import {
  addressFromApiToForm,
  CheckoutAddressForm,
  findUserAddressIdenticalToSavedCartAddress,
  formatAddressReturnToData,
  getInitialCheckoutAddressForm,
} from '~/helpers/checkout/address';
import { mergeItem } from '~/helpers/asyncLocalStorage';

import type {
  ShippingCartAddress, Country, Customer, CustomerAddress,
} from '~/modules/GraphQL/types';
import {useCartStore} from "~/modules/checkout/stores/cart";
import VueCountryCode from "vue-country-code";
import { eventBus } from '~/almarwan/helpers/EventBus';
import BillingCountries from '~/modules/catalog/pages/Countries.json'

extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('max', {
  ...max,
  message: 'Only {length} characters allowed.',
});
extend('numeric', {
  ...numeric,
  message: 'Please provide a valid phone number',
});
extend('postalCode', {
  ...required,
  message: "This field is required , please enter 00000 if unknown.",
});

export default defineComponent({
  name: 'BillingStep',
  components: {
    SfHeading,
    SfInput,
    SfButton,
    SfSelect,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
    UserBillingAddresses: () => import('~/modules/checkout/components/UserBillingAddresses.vue'),
    UserAddressDetails,
    VueCountryCode
  },
  beforeDestroy () {
    eventBus.$off('checkBilling')
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const store = useCartStore();
    const { multiShippingAddresses } = useCartStore();
    const shippingDetails = ref<ShippingCartAddress | null>(null);
    const userBilling = ref<Customer | null>(null);
    const {
      save, load: loadBilling, loading, error
    } = useBilling();
    const {
      load: loadUserBilling,
      setDefaultAddress,
    } = useUserAddress();
    const {
      load: loadShipping, getCities, error: shippingError
    } = useShipping();
    const {
      load: loadCountries,
      search: searchCountry,
    } = useCountrySearch();

    const citiesList = ref([]);
    const countries = ref<Country[]>([]);
    const country = ref<Country | null>(null);

    const shippingDetailsCountryName = computed(() => countries
      .value
      .find((countryItem) => countryItem.id === shippingDetails.value?.country.code)?.full_name_locale ?? '');

    const { isAuthenticated } = useUser();
    let oldBilling : CheckoutAddressForm | null = null;
    const sameAsShipping = ref(false);
    const showSameAsShippingAddressCheckbox = ref({});
    const billingDetails = ref<CheckoutAddressForm>(getInitialCheckoutAddressForm());
    const billingAdressSet = ref(false);
    const currentAddressId = ref<number | null>(null);
    const setAsDefault = ref(false);
    const isFormSubmitted = ref(false);
    const customerEmailAddress = ref('');
    const isAddNewAddressFormVisible = ref(true);
    const showSameShippingCheckbox = ref(true);
    const activeItem = ref('');
    const countryCode = ref('971');
    const gccList = ref(['AE', 'SA', 'OM']);
    const billingDetailsUpdated = ref(false);
    const { billingAddressFilled, sameAsShippingchecked, $patch } = useCartStore()
    const { send: sendNotification, notifications } = useUiNotification();
    const isBillingDetailsStepCompleted = ref(false);
    const addresses = computed(() => (userBilling.value ? userBillingGetters.getAddresses(userBilling.value) : []));

    const canMoveForward = computed(() => !loading.value && billingDetails.value && Object.keys(
      billingDetails.value,
    ).length > 0);

    const hasSavedBillingAddress = computed(() => {
      if (!isAuthenticated.value || !userBilling.value) {
        return false;
      }
      return addresses.value.length > 0;
    });

    const countriesList = BillingCountries;
    const regionInformation = computed(() => addressGetter.regionList(country.value));
    const cityCode = ref('')
    const { cart, load  } = useCart();

    const handleAddressSubmit = () => async () => {
      const addressId = currentAddressId.value;
      const billingDetailsData = {
        billingDetails: {
          ...billingDetails.value,
          customerAddressId: addressId === null ? null : String(addressId),
          sameAsShipping: sameAsShipping.value,
        },
      };
      if(countryCode.value.length == 2) { countryCode.value = '0'+countryCode.value; } //if code is 2 digit
      billingDetailsData.billingDetails.telephone = billingDetailsData.billingDetails.telephone.replace(/^0+/, ''); //replace leading 0
      billingDetailsData.billingDetails.telephone = '+'+countryCode.value + billingDetailsData.billingDetails.telephone;
      billingDetailsData.billingDetails.lastname = 'NA';
      billingDetailsData.billingDetails.postcode = '000';//no postal code in view, so setting default value to prevent val. error from BE
      delete billingDetailsData.billingDetails.customerAddressId
      let res = await save(billingDetailsData);
      if (error.value.save === null) {
        if(res !== null && res !== undefined) {
          billingAdressSet.value = true
          $patch((state)=>{
            state.billingAddressFilled = true
          })
        }
        if (addressId !== null && setAsDefault.value) {
          const [chosenAddress] = userBillingGetters.getAddresses(
            userBilling.value,
            { id: addressId },
          );
          chosenAddress.default_billing = setAsDefault.value;
          if (chosenAddress) {
            await setDefaultAddress({ address: chosenAddress });
            userBilling.value = await loadUserBilling(true);
          }
        }
        await mergeItem('checkout', { billing: billingDetailsData });
        isBillingDetailsStepCompleted.value = true;
      } else {
        sendNotification({
          id: Symbol('product_removed'),
          message: `${error.value.save}`,
          type: 'danger',
          icon: '',
          persist: false,
          title: 'Add Shipping',
        });
      }
    };

    const pushBillingForm = () => {
      activeItem.value = '';
      if(billingDetailsUpdated.value) {
        validateBilling()
      }
    }
    const validateBilling = () => {
      if(billingDetails.value.firstname?.length >= 2 && billingDetails.value.street?.length >= 2 && billingDetails.value.country_code?.length >= 2 && billingDetails.value.city?.length > 2 && billingDetails.value.telephone?.length >= 8) {
        let applyBilling = document.getElementById("billingSubmit");
        if (applyBilling) {
          applyBilling.click()
        }
      } else {
        $patch((state)=>{
          state.billingAddressFilled = false
        })
      }
    }
    const handleCheckSameAddress = async (value: boolean) => {
      sameAsShipping.value = value;
      shippingDetails.value = await loadShipping();
      if(shippingError.value.load === null && shippingDetails.value){
        if (value) {
          $patch((state)=>{
            state.sameAsShippingchecked = true
          })
          // shippingDetails.value = await loadShipping();
          country.value = await searchCountry({ id: (shippingDetails.value).country.code });
          oldBilling = { ...billingDetails.value };
          billingDetails.value = {
            ...formatAddressReturnToData(shippingDetails.value),
          };
          currentAddressId.value = null;
          setAsDefault.value = false;
          if (billingDetails.value.country_code) {
            country.value = await searchCountry({ id: billingDetails?.value.country_code });
          }
          let applyBilling = document.getElementById("billingSubmit");
          if (applyBilling) {
            applyBilling.click()
          }
          return;
        } else {
          billingAdressSet.value = false;
          $patch((state)=>{
            state.sameAsShippingchecked = false;
            state.billingAddressFilled = false;
          })
        }
        billingDetails.value = oldBilling;
        let applyBilling = document.getElementById("billingSubmit");
        if (applyBilling) {
            applyBilling.click()
        }
        //billingDetails.value.telephone = '';
        if (billingDetails.value.country_code) {
          country.value = await searchCountry({ id: billingDetails?.value.country_code });
        }
      } else {
        sameAsShipping.value = false;
        sendNotification({
          id: Symbol('product_removed'),
          message: 'Please add the shipping details.',
          type: 'warning',
          icon: '',
          persist: false,
          title: 'Add Shipping',
        });
      }
    };

    const handleAddNewAddressBtnClick = () => {
      currentAddressId.value = null;
      billingDetails.value = getInitialCheckoutAddressForm();
      isAddNewAddressFormVisible.value = true;
      isBillingDetailsStepCompleted.value = false;
    };

    const handleSetCurrentAddress = async (customerAddress: CustomerAddress) => {
      currentAddressId.value = customerAddress?.id;
      billingDetails.value = addressFromApiToForm(customerAddress);
      country.value = customerAddress.country_code ? await searchCountry({ id: customerAddress.country_code }) : null;
      isBillingDetailsStepCompleted.value = false;
    };

    const changeBillingDetails = (field: keyof CheckoutAddressForm, value: string) => {
      billingDetails.value[field] = value;
      currentAddressId.value = null;
      isBillingDetailsStepCompleted.value = false;
      //disable submit
      if(field==='telephone') {
        if(value.length < 8){
          $patch((state)=>{
            state.billingAddressFilled = false
          });
        }
        else {
          $patch((state)=>{
            state.billingAddressFilled = true
          });
        }
      }
      if(value.length<2){
        $patch((state)=>{
          state.billingAddressFilled = false
        })
      }
      billingDetailsUpdated.value = true;
    };

    const changeCountry = async (id: string) => {
      changeBillingDetails('country_code', id);
      const newCountry = await searchCountry({ id });
      billingDetails.value.region = '';
      country.value = newCountry;
      citiesList.value =  await getCities(id, '')
    };

    const changeCity = async (code: string) => {
      let city = citiesList.value.find(o => o.code == code);

      billingDetails.value.postcode = city.id;
      cityCode.value = city.code;
      billingDetails.value.city = city.name;
      changeBillingDetails('city', city.name);
    };
    const getCityCode = computed((cityName) => {

      if(citiesList.value?.length) {
        let city= citiesList?.value?.find(o => o.name == cityName);
        return city?.code;
      }
    });

    const onSelectCode = (event) => {
      countryCode.value = event.dialCode;
    };

    const setActive = (item) => {
      activeItem.value = item;
    }

    store.$subscribe(async (mutation, state) => {
      showSameShippingCheckbox.value = state.allAddressesArePickup
    })

    onMounted(async () => {
      const [loadedBillingInfoBoundToCart, loadedUserBilling, loadedCountries] = await Promise.all([
        loadBilling(),
        loadUserBilling(),
        loadCountries(),
      ]);

      if (loadedUserBilling) {
        customerEmailAddress.value = loadedUserBilling?.email
      }
      const [defaultAddress = null] = userBillingGetters.getAddresses(loadedUserBilling, { default_shipping: true });
      const wasBillingAddressAlreadySetOnCart = Boolean(loadedBillingInfoBoundToCart);

      // keep in mind default billing address is set on a customer's cart during cart creation
      if (wasBillingAddressAlreadySetOnCart) {
        const userAddressIdenticalToSavedCartAddress = findUserAddressIdenticalToSavedCartAddress(
          loadedUserBilling?.addresses,
          loadedBillingInfoBoundToCart,
        );

        await handleSetCurrentAddress({ ...loadedBillingInfoBoundToCart, id: userAddressIdenticalToSavedCartAddress?.id });
      } else if (defaultAddress) {
        await handleSetCurrentAddress(defaultAddress);
      }

      userBilling.value = loadedUserBilling;
      countries.value = loadedCountries;
      if(hasSavedBillingAddress.value == true){
        $patch((state)=>{
          state.billingAddressFilled = true
        })
      }
      $patch((state)=>{
        if(state.sameAsShippingchecked){
          //sameAsShipping.value = true
          handleCheckSameAddress(true)
        }
      })
      if(billingDetails.value.telephone && billingDetails.value.telephone.length > 9) {
        billingDetails.value.telephone = billingDetails.value.telephone.substring(billingDetails.value.telephone.length - 9);
      }
      if(billingDetails.value.country_code) {
        let ids = countries?.value?.find(o => o.id == billingDetails.value.country_code)
        if(typeof ids === 'undefined') {
          billingDetails.value.country_code = 'AE'
        }
        const id = billingDetails.value.country_code
        citiesList.value = await getCities(id, billingDetails.value.city);
        setTimeout(() => {
          if(billingDetails.value?.city && billingDetails.value?.city.length > 3){
            let name= citiesList?.value?.find(o => o.name == billingDetails.value.city);
            if(typeof name !== 'undefined') {
              cityCode.value = name.code;
            }
          }
          else {
            cityCode.value = billingDetails.value.city;
          }
        }, 1000)
      }
      showSameAsShippingAddressCheckbox.value = await loadShipping();

      eventBus.$on('checkBilling', () => {
        validateBilling()
      })
    });

    return {
      multiShippingAddresses,
      showSameShippingCheckbox,
      showSameAsShippingAddressCheckbox,
      citiesList,
      customerEmailAddress,
      isAddNewAddressFormVisible,
      canMoveForward,
      changeCountry,
      changeCity,
      changeBillingDetails,
      countriesList,
      countries,
      country,
      currentAddressId,
      handleAddNewAddressBtnClick,
      handleAddressSubmit,
      handleSetCurrentAddress,
      handleCheckSameAddress,
      hasSavedBillingAddress,
      isAuthenticated,
      isFormSubmitted,
      isBillingDetailsStepCompleted,
      loading,
      regionInformation,
      searchCountry,
      setAsDefault,
      billingDetails,
      sameAsShipping,
      shippingDetailsCountryName,
      addresses,
      billingAddressFilled,
      sameAsShippingchecked,
      billingAdressSet,
      onSelectCode,
      setActive,
      activeItem,
      billingDetailsUpdated,
      getCityCode,
      cityCode,
      pushBillingForm
    };
  },
});
