





























































































































































































import {defineComponent} from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {SfButton, SfCheckbox, SfInput, SfSelect, SfTextarea} from "@storefront-ui/vue";
import {computed, ref} from "@nuxtjs/composition-api";
import {Countries, Country, useCountrySearch, UseCountrySearchParams} from "~/composables";
import addressGetter from "~/modules/customer/getters/addressGetter";

export default defineComponent({
  name: 'LocalDelivery',
  components: {
    SfInput,
    SfButton,
    SfSelect,
    SfCheckbox,
    SfTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    loading: {
      type: Boolean,
      required: false
    }
  },
  setup() {
    const { load: loadCountries, search: searchCountry } = useCountrySearch();
    const form = ref({
      address: '',
      region: { region_code: '', region_id: null },
      country_code: '',
      city: '',
      default_shipping: false,
      firstname: null,
      email: null,
      telephone: '',
      street: null
    })
    const countries = ref<Countries[]>([]);
    const countriesList = computed(() => addressGetter.countriesList(countries.value));
    const country = ref<Country | null>(null);
    const regionInformation = computed(() => addressGetter.regionList(country.value));

    const updateCountry = async (params: UseCountrySearchParams) => {
      country.value = await searchCountry(params);
      // form.value.region = {
      //   // let region SfSelect know it should display initial state
      //   ...(regionInformation.value.length > 0 ? { region_id: null } : {}),
      // };
    };
    const submitForm = () => {

    }

    return {
      form,
      submitForm,
      updateCountry,
      countriesList,
    }
  }
})
