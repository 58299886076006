


























































































































import { defineComponent, ref, watch, computed, onMounted, onBeforeMount, useRouter, useRoute, onBeforeUnmount } from '@nuxtjs/composition-api';
import BreadCrumb from "~/almarwan/components/Breadcrumb.vue";
import Icon from '@/almarwan/components/Icon.vue'
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useUserOrder } from '~/modules/customer/composables/useUserOrder';
import {useCartStore} from "~/modules/checkout/stores/cart";
import {useCustomerStore} from "~/modules/customer/stores/customer";
import { useCart } from '~/modules/checkout/composables/useCart';

export default defineComponent({
  name: "offer-confirmation",
  components: {
    SkeletonLoader,
    BreadCrumb,
    Icon,
  },
  data() {
    return {
      breadcrumbs: [
        {
          link: '/offer-confirmation',
          text: 'Offer Confirmation'
        }
      ],
      breadcrumbsFin: [
        {
          link: '/',
          text: 'Product Details'
        },
        {
          link: '/offer-confirmation',
          text: 'Financing'
        }
      ],

    }
  },
  props: {
    successTypeInput: {
      type: String,
      default: 'order'
    },
    quoteId: {
      type: Number,
      default: null
    },
    orderStatus: {
      type: String,
      default: null
    },
    orderId: {
      type: String,
      default: null
    },
    error: {
      type: Array,
      default: null
    }
  },
  methods: {
    goToMyQuote(quoteId) {
      if (quoteId) {
        // @ts-ignore
        this.router.push({path: `/customer/single-quote/${quoteId}`});
      }
    },
    goToMyOffers() {
     this.router.push({ path: '/customer/my-offers'});
    },
    goToMyOrders() {
     this.router.push({ path: '/customer/order-history'});
    },
    continueShopping() {
      if (this.isRentOrBuy === 'rent') {
        this.router.push({ path: '/c/equipment-rent'});
      }
      if (this.isRentOrBuy === 'buy') {
        this.router.push({ path: '/c/equipment-buy'});
      }
    }
  },
  setup(props) {
    const { loadingQuote, errorSingleQuote, quotationPDF } = useUserOrder();
    const successType = ref('order')
    const router = useRouter()
    const { load: loadCart } = useCart();
    const quotePdf = ref(null)
    const getQuoteDetail = ref([])
    const isFinanceProduct = ref(false)
    const store = useCartStore();
    const { $patch, financeProductOnCart } = useCartStore();
    const { isRentOrBuy } = useCustomerStore();

    watch(()=> props.successTypeInput, () => {
      successType.value = props.successTypeInput
    })

    const downloadPDF = () => {
      const downloadLink = document.createElement("a");
      const fileName = `quote_${props.quoteId}.pdf`;
      downloadLink.href = quotePdf.value;
      downloadLink.download = fileName;
      downloadLink.click();
    }

    const financeRequestOrder = () => {
      let url
      if (props.orderId) {
        url = router.push(`/credit-application?order_id=${props.orderId}`)
      } else {
        url = router.push(`/credit-application`)
      }
      return url
    }
    watch(() => store.financeProductOnCart, () => {
     if(store.financeProductOnCart == true) {
      isFinanceProduct.value = true
     }
    });

    onMounted(async () => {
      successType.value = props.successTypeInput
      if (successType.value === 'quote') {
        await Promise.all([loadCart()]);
        getQuoteDetail.value = await quotationPDF(Number(props.quoteId))
        // @ts-ignore
        if (getQuoteDetail.value && getQuoteDetail.value?.items) {
          // @ts-ignore
          quotePdf.value = getQuoteDetail.value?.items[0]?.pdf
        }
      }
      if(store.financeProductOnCart == true) {
        isFinanceProduct.value = true
      }
    })

    onBeforeUnmount(() => {
      store.financeProductOnCart = false
      isFinanceProduct.value = false
    })

    return {
      isRentOrBuy,
      financeRequestOrder,
      router,
      successType,
      quotePdf,
      downloadPDF,
      isFinanceProduct,
      financeProductOnCart
    }
  }
});
