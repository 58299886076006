











































import { defineComponent, onMounted, ref } from "@nuxtjs/composition-api";
import useUserAddress from "~/modules/customer/composables/useUserAddress";
import { useCartStore } from "~/modules/checkout/stores/cart";
import { useUser } from "~/composables";

export default defineComponent({
  name: 'PickUp',
  props: {
    product: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const { load: loadUserShipping } = useUserAddress();
    const { cartProductQuantity } = useCartStore();
    const { isAuthenticated } = useUser();
    const sameShippingForAllItems = ref(false)
    const loadUserData = ref([])
    const dataForShippingMethod = ref( {
      customer_address_id : 0,
      customer_notes: '',
      method: 'flatrate_flatrate',
      dropDown: true,
      sameForAll: false
    })

    const getUserDefaultAddressShippingAddress = (userData) => {
      loadUserData.value = userData.addresses?.filter((address) => address.default_shipping === true)
    }

    const sendMethodData = async () => {
      dataForShippingMethod.value.customer_address_id = 0
      dataForShippingMethod.value.dropDown = true
      emit('dataForShippingMethod', dataForShippingMethod.value)
    }

    const samePickupCheck = () => {
      dataForShippingMethod.value.sameForAll = sameShippingForAllItems.value
      emit('dataForShippingMethod', dataForShippingMethod.value)
    }

    onMounted(async () => {
      const [loadedUserShipping] = await Promise.all([
        loadUserShipping()
      ]);
      if (isAuthenticated.value) {
        await getUserDefaultAddressShippingAddress(loadedUserShipping)
        await sendMethodData()
      }
    })

    return {
      cartProductQuantity,
      samePickupCheck,
      sameShippingForAllItems,
      dataForShippingMethod,
    }
  }
})
