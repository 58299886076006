




































































































































import {
  ref,
  computed,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';
import PaymentOptions from '~/modules/checkout/pages/Checkout/PaymentOptions.vue'
import PaymentProduct from '~/modules/checkout/pages/Checkout/PaymentProduct.vue'
import BillingDetails from '~/modules/checkout/pages/Checkout/Billing.vue'
import useCart from '~/modules/checkout/composables/useCart';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import BreadCrumb from "~/almarwan/components/Breadcrumb.vue"
import usePaymentProvider from '~/modules/checkout/composables/usePaymentProvider';
import { eventBus } from "@/almarwan/helpers/EventBus";
import useMakeOrder from '~/modules/checkout/composables/useMakeOrder';
import { removeItem } from '~/helpers/asyncLocalStorage';
import StripeComponent from '~/modules/checkout/pages/Checkout/StripeComponent.vue'
import { useUiNotification } from '~/composables';
import {useCartStore} from "~/modules/checkout/stores/cart";
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import SavedCards from '~/modules/checkout/pages/Checkout/SavedCards.vue';
import { useUserOrder } from '~/modules/customer/composables/useUserOrder';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  name: 'PaymentPage',
  components: {
    PaymentOptions,
    PaymentProduct,
    BillingDetails,
    BreadCrumb,
    StripeComponent,
    SkeletonLoader,
    SavedCards
  },
  data() {
    return {
      breadcrumbs: [{
        link: '/Checkout',
        text: 'Checkout'
      }],
      token: null,
    }
  },
  props: {
    appliedTaxValue: {
      type: Number,
      default: 0,
    },
    payBalanceOn: {
      type: Boolean,
      default: false
    },
    payBalanceOrder: {
      type: String,
      default: null
    }
  },
  mounted() {
    eventBus.$on('paymentMethodId', (val) => {
      if (val) {
        this.getPaymentMethod(val)
      }
    });
    eventBus.$on('submitPayBalance', (val) => {
      if (val) {
        this.submitPayBalance()
      }
    });
  },
  beforeDestroy() {
    eventBus.$off('paymentMethodId')
  },
  setup(props, {emit}) {
    const { save, error } = usePaymentProvider();
    const { makeV2, errorV2 } = useMakeOrder();
    const { app } = useContext();
    const { cart, load, setCart } = useCart();
    const { search, loading, setPaymentMethodOnOrder, payForOrder, errorPayOrder } = useUserOrder();
    const { $patch, checkShippingMethod } = useCartStore()
    const store = useCartStore()
    const { customerSetDefaultCard } = useUser();
    const { send: sendNotification } = useUiNotification();
    const order = ref(null);
    const subTotal = ref(0);
    const customerSelectedMethod = ref('stripe_payments');
    const selectedWTMethod = ref('manual-transfer');
    const pageReady = ref(false);
    const selectedCard = ref(null);
    const saveCardCheck = ref('')
    const showSaved = ref(false);
    const isMobile = ref(false);
    let stripeToken = '';
    let orderLoading = ref(false);
    const payBalanceItem = ref(null)
    const payBalanceDue = ref(0)
    const payBalanceId = ref(null)
    const payBalancePaymentMethod = ref(null)
    const temrmsVal =  ref(false)
    const payBalanceTnC = ref(false)
    const tokenCreated = (token) => {
      stripeToken = token.card.id;
    }

    const getPaymentMethod = async (token = null) => {
        if (customerSelectedMethod.value === 'stripe_payments' && token !== null) {
          orderLoading.value = true
          await save({
            paymentMethod: {
              code: "stripe_payments",
              stripe_payments: {
                cc_stripejs_token: token,
                save_payment_method: saveCardCheck.value === 'true'
              }
            },
          });
          if (error.value.save === null) {
            await processOrder();
          } else {
            orderLoading.value = false
            sendNotification({
              id: Symbol('product_removed'),
              message: `${error.value.save}`,
              type: 'danger',
              icon: 'error',
              persist: false,
              title: 'Something went wrong!',
            });
          }
        }
    };

    const sendWireTransfer = async (val) => {
    if (customerSelectedMethod.value === 'banktransfer' && val === 'no' ) {
        await processOrder();
      }
    }

    const processOrder = async () => {
      order.value = await makeV2();
      $patch((state) => {
          state.payNowLoading = false;
        })
      if(order.value?.errors?.length > 0) {
        orderLoading.value = false
        emit('orderProcessed', { status: 'failed', from: 'order', quote: null, orderError: order.value.errors, orderNumber: null})
        document.documentElement.scrollIntoView({ behavior: "smooth" })
      } else if (order.value && order.value?.orders?.length > 0) {
        setCart(null);
        app.$vsf.$magento.config.state.removeCartId();
        await load();
        await removeItem('checkout');
        orderLoading.value = false
        emit('orderProcessed', { status: 'succeeded', from: 'order', quote: null, orderError: null, orderNumber: order.value?.orders[0]?.order_id} )
        document.documentElement.scrollIntoView({ behavior: "smooth" })
      }
      else {
        if(order.value === null) {
          orderLoading.value = false
          emit('orderProcessed', { status: 'failed', from: 'order', quote: null, orderError: 'Unable to place the order', orderNumber: null})
        }
      }
    };
    const termsFunction = () => {
      if(!props.payBalanceOn) {
        eventBus.$emit('checkBilling');
        checkTerms(temrmsVal.value);
      }
      else {
        payBalanceTnC.value = temrmsVal.value
      }
    }

    const checkTerms = ((val)=> {
      if(val) {
        $patch((state)=>{
          state.tAndcChecked = true;
        })
      }
      else
      {
        $patch((state)=>{
          state.tAndcChecked = false;
        })
      }
    })

    const selectedMethod = async (method) => {
      if(isMobile.value)
        window.scrollTo(0, window.scrollY + 450)
      customerSelectedMethod.value = method
      if(props.payBalanceOn) {
        await setPaymentMethodOnOrder(Number(atob(payBalanceId.value)), customerSelectedMethod.value)
      }
      else {
        if (customerSelectedMethod.value === 'banktransfer') {
          await save({
            // @ts-ignore
            paymentMethod: {
              code: 'banktransfer'
            }
          });
        }
      }
    }
    const selectWireTransferMethod = (method) => {
      selectedWTMethod.value = method
    }
    const cardError = (val) =>{
      orderLoading.value = false
    }
    const paymentMethods = computed(() => cart.value && cart.value?.available_payment_methods)
    const getPayBalanceOrder = async() => {
      const orderResult = await search({ filter: { number: { eq: props.payBalanceOrder   } }, list: true });
      customerSelectedMethod.value = orderResult.items[0]?.payment_methods[0]?.type;
      payBalanceItem.value = orderResult.items[0]?.items[0]
      //@ts-ignore
      payBalanceDue.value = orderResult.items[0]?.total_due
      payBalanceId.value = orderResult.items[0]?.id
      payBalancePaymentMethod.value = orderResult.items[0]?.payment_methods[0]?.type
    }
    const setAsDefault = async(card) => {
      await customerSetDefaultCard(card.id);
    }
    const submitPayBalance = async () =>  {
      let decodeId = Number(atob(payBalanceId.value))
      if(decodeId) {
        await payForOrder(decodeId).then(() => {
          if (errorPayOrder.value.order === null) {
            eventBus.$emit('payForOrderSuccess', true)
          }
        })
        if (errorPayOrder.value.order && errorPayOrder.value.order[0].message) {
          eventBus.$emit('payForOrderSuccess', false)
          sendNotification({
            id: Symbol('product_added_to_cart_from_wishlist'),
            message: errorPayOrder.value?.order[0]?.message || 'Payment failed due to some issue, Please try again',
            type: 'danger',
            icon: 'cross',
            persist: false,
            title: 'Upcoming payment',
          });
        }
      }
    }
    store.$subscribe(async (mutation, state) => {
      if(state.checkShippingMethod) {
        emit('revertToLastStep')
      }
    });
    onMounted(async () => {
      if(!props.payBalanceOn) {
        await load();
        if(cart.value && cart.value['items'].length > 0) {
          subTotal.value = cart.value?.prices?.subtotal_excluding_tax?.value;
          pageReady.value = true;
        }
      }
      else {
        getPayBalanceOrder()
      }
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        isMobile.value = true;
      }
      eventBus.$on('startLoading', (val) => {
        orderLoading.value = val
      })
      eventBus.$on('savedCardSlelected', (card) => {
        if (card) {
          selectedCard.value = card;
          setAsDefault(card)
        }
        else {
          selectedCard.value = null;
        }
      });
    });

    return {
      sendWireTransfer,
      paymentMethods,
      selectedWTMethod,
      selectWireTransferMethod,
      customerSelectedMethod,
      selectedMethod,
      processOrder,
      cart,
      pageReady,
      tokenCreated,
      getPaymentMethod,
      subTotal,
      checkTerms,
      orderLoading,
      selectedCard,
      saveCardCheck,
      showSaved,
      cardError,
      payBalanceItem,
      payBalanceDue,
      payBalanceId,
      payBalancePaymentMethod,
      termsFunction,
      temrmsVal,
      payBalanceTnC,
      submitPayBalance
    }
  },
})
