var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"arrange-my-own-shipping"},[_c('div',{staticClass:"deliver-to-my-address"},[_c('div',{staticClass:"heading-title-2"},[_vm._v("Delivery Details")]),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"delivery-address"},[_c('ValidationProvider',{staticClass:"form__element city",attrs:{"rules":"required|min:4","name":"destination"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"destination","label":"Destination","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.destination),callback:function ($$v) {_vm.$set(_vm.form, "destination", $$v)},expression:"form.destination"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{attrs:{"name":"agencies","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfSelect',{staticClass:"form__select sf-select--underlined select-change",attrs:{"label":"Shipping-Agency","name":"agencies","required":"","placeholder":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},on:{"input":function($event){return _vm.updateCountry({ id: $event })}},model:{value:(_vm.form.agencies),callback:function ($$v) {_vm.$set(_vm.form, "agencies", $$v)},expression:"form.agencies"}},[_c('SfSelectOption',{attrs:{"value":''}}),_vm._v(" "),_vm._l((_vm.countriesList),function(countryOption){return _c('SfSelectOption',{key:countryOption.id,attrs:{"value":countryOption.abbreviation}},[_vm._v("\n                  "+_vm._s(countryOption.label)+"\n                ")])})],2)]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"contact-info"},[_c('div',{staticClass:"heading-title-2"},[_vm._v("Contact Info For Delivery")]),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:2","name":"firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"firstname","placeholder":"Full Name","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"type":"email","placeholder":"Email Address","name":"email","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:10","name":"telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"telephone","placeholder":"Phone number","type":"number","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", $$v)},expression:"form.telephone"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"instructions"},[_c('p',[_vm._v("After completing the purchase, you will be redirected to the shipping agency’s page.\n              There you will be able to agree on the details and make the payment for the shipping service.")])])],1)])]}}])})],1),_vm._v(" "),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"info-section"},[_c('div',{staticClass:"title"},[_vm._v("Important Info")]),_vm._v(" "),_c('div',{staticClass:"important-info"},[_c('p',[_vm._v("Buyer is responsible for all costs related to transporting the item.")]),_vm._v(" "),_c('p',[_vm._v("Item must be picked up within 8 business days, or the buyer will incur storage charges. "),_c('br'),_vm._v("\n          Read"),_c('span',{staticClass:"read"},[_vm._v(" Terms & Conditions.")])])])])])}]

export { render, staticRenderFns }