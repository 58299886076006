





































































































































































































































































































































import {
  SfHeading, SfInput, SfButton, SfSelect, SfCheckbox, SfTextarea
} from '@storefront-ui/vue';
import {
  ref,
  computed,
  onMounted,
  defineComponent
} from '@nuxtjs/composition-api';
import {required, min, max, numeric} from 'vee-validate/dist/rules';
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import userShippingGetters from '~/modules/customer/getters/userShippingGetters';
import addressGetter from '~/modules/customer/getters/addressGetter';
import { CountryCodeEnum, useAddresses, useCountrySearch, useUiNotification } from '~/composables';
import type {
  Country, AvailableShippingMethod, CustomerAddress, Customer,
} from '~/modules/GraphQL/types';
import useShipping from '~/modules/checkout/composables/useShipping';
import useUser from '~/modules/customer/composables/useUser';
import useUserAddress from '~/modules/customer/composables/useUserAddress';
import {
  CheckoutAddressForm, getInitialCheckoutAddressForm,
} from '~/helpers/checkout/address';
import { useCartStore } from "~/modules/checkout/stores/cart";
import VueCountryCode from "vue-country-code";
import { TransformedCustomerAddress } from "~/modules/customer/composables/types";
import { PropType } from "vue";
import {SubmitEventPayload} from "~/modules/customer/types/form";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import EstimatorBox from '~/modules/catalog/product/components/product-types/simple/EstimatorBox.vue';
import { clickOutside } from "~/components/directives/click-outside/click-outside-directive";

extend('required', {
  ...required,
  message: 'The field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('max', {
  ...max,
  message: 'Only {length} characters allowed.',
});
extend('numeric', {
  ...numeric,
  message: 'Please provide a valid phone number',
});
extend('postalCode', {
  ...required,
  message: "This field is required , please enter 00000 if unknown.",
});

export default defineComponent({
  name: 'ShippingStep',
  directives: { clickOutside },
  components: {
    SfTextarea,
    SfInput,
    SfSelect,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
    VueCountryCode,
    DatePicker,
    UserShippingAddresses: () => import('~/modules/checkout/components/UserShippingAddresses.vue'),
    EstimatorBox
  },
  props: {
    address: {
      type: Object as PropType<TransformedCustomerAddress>,
      default: () => ({
        id: '',
        apartment: '',
        city: null,
        country_code: '' as CountryCodeEnum,
        firstname: '',
        lastname: '',
        postcode: '',
        region: {
          region_code: '',
          region_id: 0,
        },
        street: '',
        telephone: '',
        default_shipping: false,
        default_billing: false,
      }),
    },
    product: {
      type: Object,
      required: true
    },
    addedAddress: {
      type: [Array, Object],
      required: true
    }
  },
  mounted() {
    this.observeFormChanges();
  },
  setup(props, {emit}) {
    const {loading: isShippingLoading, getCities} = useShipping();
    const {load: loadUserShipping} = useUserAddress();
    const {load: loadCountries, search: searchCountry} = useCountrySearch();
    const {send: sendNotification} = useUiNotification();
    const {save: saveNewAddress} = useAddresses();
    const {isAuthenticated, estimateShippingMethods} = useUser();
    const {shippingAdressFilled, $patch, cartProductQuantity} = useCartStore()
    const userShipping = ref<Customer | null>(null);
    const countries = ref<Country[]>([]);
    const country = ref<Country | null>(null);
    const city = ref(null);
    const activeItem = ref('');
    const shippingDetails = ref<CheckoutAddressForm>(getInitialCheckoutAddressForm());
    const shippingMethods = ref<AvailableShippingMethod[]>([]);
    const form = ref({
      apartment: props.address.apartment,
      city: props.address.city,
      country_code: props.address.country_code,
      firstname: props.address.firstname,
      lastname: props.address.lastname || 'test',
      postcode: props.address.postcode,
      region: {
        region_code: '',
        region_id: null,
        ...props.address.region,
      },
      street: props.address.street,
      telephone: props.address.telephone,
      default_shipping: props.address.default_shipping || false,
      default_billing: props.address.default_billing || false,
    });
    const formRef = ref(null);
    const dataForShippingMethod = ref({
      customer_address_id: 0,
      customer_notes: '',
      method: 'marwanrate_marwanrate',
      dropDown: true,
      sameForAll: false
    })
    const currentAddressId = ref<number | null>(null);
    const isSetAsDefaultRequested = ref(false);
    const showAddressBook = ref(false);
    const isFormSubmitted = ref(false);
    const isAddNewAddressFormVisible = ref(true);
    const countryCode = ref('971');
    const email = ref('');
    const sameShippingForAllItems = ref(false);
    const submitBtn = ref(false);
    const emailValidation = ref(false);
    const preSelectedAddress = ref(false);
    const isSubmitting = ref(false);
    const showSetAsFutureAddress = ref(true)
    const customerNotes = ref('');
    const selectedDate = ref(null);
    const selectedTime = ref(null);
    const citiesList = ref([]);
    const timeList = ref([
      {
        name: 'Morning',
        value: '08:00'
      },
      {
        name: 'Afternoon',
        value: '12:00'
      },
      {
        name: 'Evening',
        value: '17:00'
      }
    ]);
    const selCityName = ref('--');
    const selCityCode = ref('');
    const selCountryId = ref('');
    const estimatedPrice = ref(0);
    const estimatedDays = ref('--');
    const gccList = ref(['SA', 'OM', 'QA', 'KW', 'BH']);
    const defaultShipping = ref(false);
    const isShippingDetailsStepCompleted = ref(false);

    const addresses = computed(() => userShippingGetters.getAddresses(userShipping.value));
    const canMoveForward = computed(() => !isShippingLoading.value && shippingDetails.value && Object.keys(
      shippingDetails.value,
    ).length > 0);
    const hasSavedShippingAddress = computed(() => {
      if (!isAuthenticated.value || !userShipping.value) {
        return false;
      }
      return addresses.value.length > 0;
    });

    const countriesList = computed(() => addressGetter.countriesList(countries.value));

    const regionInformation = computed(() => addressGetter.regionList(country.value));

    const getCountryList = computed(() => {
      if(props.product?.shippable_countries?.length) {
        let tempArr = props.product.shippable_countries
          .filter((c) => c.id && c.full_name_english)
          .map((c) => ({
            id: c.id,
            label: c.full_name_english,
            englishLabel: c.full_name_english,
            abbreviation: c.two_letter_abbreviation,
          }))
        return tempArr
      }
      else {
        return countriesList.value
      }
    })

    const observeFormChanges = () => {
      const targetNode = document.querySelector('.shipping-fom-selector');
      const observerOptions = {
        attributes: true,
        childList: true,
        subtree: true
      };
      const observer = new MutationObserver(handleMutation);
      observer.observe(targetNode, observerOptions);
    }
    const handleMutation = (mutationsList: any, observer: any) => {
      const errorElement = document.querySelector('.sf-select.is-invalid') || document.querySelector('.sf-input.invalid');
      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
    const submitForm = () => {
      if (isSubmitting.value) {
        return;
      }
      isSubmitting.value = true
      const regionId = regionInformation.value.find((r) => r.abbreviation === form.value.region.region_code)?.id;
      if (regionId) {
        form.value.region.region_id = regionId;
      }
      let city = citiesList.value.find((city) => city.id === Number(form.value.city))
      if (city) form.value.city = city.code
      const onComplete = () => {
        sendNotification({
          id: Symbol('user_updated'),
          message: 'Shipping address added successfully!',
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'User Account',
        });
      };
      const onError = (message: string) => {
        sendNotification({
          id: Symbol('user_updated'),
          message: 'Adding shipping address failed, Please try again!',
          type: 'danger',
          icon: 'cross',
          persist: false,
          title: 'User Account',
        });
      };
      // @ts-ignore
      const eventPayload: SubmitEventPayload<TransformedCustomerAddress> = {form: form.value, onComplete, onError};
      setTimeout(() => {
        isSubmitting.value = false;
      }, 3000);
      add(eventPayload);
    };

    const add = async ({form}: SubmitEventPayload<CustomerAddress>) => {
      await saveNewAddress({address: {...form}}).then(async (res) => {
        dataForShippingMethod.value.customer_address_id = res.id
        dataForShippingMethod.value.dropDown = false
        dataForShippingMethod.value.method = 'marwanrate_marwanrate'
        if (dataForShippingMethod.value.customer_address_id) {
          emit('dataForShippingMethod', dataForShippingMethod.value)
        }
        submitBtn.value = true
      });
    };

    const handleAddNewAddressBtnClick = () => {
      currentAddressId.value = null;
      shippingDetails.value = getInitialCheckoutAddressForm();
      isAddNewAddressFormVisible.value = true;
      isShippingDetailsStepCompleted.value = false;
    };

    const handleSetCurrentAddress = async (customerAddress: CustomerAddress) => {
      let selCityId = '';
      showSetAsFutureAddress.value = false
      selCityCode.value = customerAddress?.city;
      selCountryId.value = customerAddress?.country_code;
      if(selCityName.value === '--') {
        citiesList.value = await getCities(selCountryId.value, '')
        let obj = citiesList.value.find(o => o.code === selCityCode.value);
        selCityName.value = obj?.name;
        selCityId = obj?.id
      }
      else {
        selCityId = selCityName.value
      }
      await getEstimation(selCityId);
      const id = customerAddress?.id;
      currentAddressId.value = id;
      if (id) {
        isAddNewAddressFormVisible.value = false;
        dataForShippingMethod.value.customer_address_id = id
      }
      // @ts-ignore
      form.value.street = customerAddress.street.toString()
      // @ts-ignore
      form.value.city = customerAddress.city
      form.value.postcode = customerAddress.postcode;
      form.value.firstname = customerAddress.firstname;
      form.value.default_shipping = false;
      form.value.country_code = customerAddress.country_code;
      form.value.telephone = customerAddress.telephone;
      country.value = customerAddress.country_code ? await searchCountry({id: customerAddress.country_code}) : null;
      await getCity(country.value, customerAddress.city)
      preSelectedAddress.value = true
      dataForShippingMethod.value.customer_address_id = customerAddress.id
      if (!email.value) getEmail()
      updateCustomerNotes()
    };

    const changeAddressPhone = (field, value) => {
      if (field === 'telephone' && value.length < 8) {
        $patch((state) => {
          state.billingAddressFilled = false
        })
      }
    }

    const removeDefaultSelectedAddress = () => {
      showAddressBook.value = false;
      form.value.street = null;
      form.value.city = null;
      form.value.postcode = '';
      form.value.firstname = null;
      form.value.default_shipping = false;
      form.value.country_code = null;
      form.value.telephone = null;
      isAddNewAddressFormVisible.value = true;
      currentAddressId.value = null
      preSelectedAddress.value = false
      city.value = null
    }

    const changeShippingDetails = (field: keyof CheckoutAddressForm, value: string) => {
      shippingDetails.value[field] = value;
      isShippingDetailsStepCompleted.value = false;
      currentAddressId.value = null;
      //disable submit
      if (field === 'telephone' && value.length < 8) {
        $patch((state) => {
          state.billingAddressFilled = false
        })
      }
      if (value.length < 2) {
        $patch((state) => {
          state.billingAddressFilled = false
        })
      }
    };

    const getCity = async (country, cityId) => {
      const id = country.id
      const cities = await getCities(id, cityId)
      city.value = cities.find((city) => city.code === cityId)
    }

    const changeCity = (val) => {
      let obj = citiesList.value.find(o => o.code == val);
      selCityName.value = obj?.name
      form.value.postcode = obj?.id
      getEstimation(obj?.id)
    }

    const getEstimation = async (cityId) => {
      if (selCountryId.value === '') {
        selCountryId.value = country.value.id;
      }
      let estArr = [{sku: props?.product?.sku, countryId: selCountryId.value, cityId: cityId}]
      const estimateData = await estimateShippingMethods(estArr)
      // @ts-ignore: Unreachable code error
      let selectedEstimate = estimateData?.estimateShippingMethods?.filter((item) => item.carrier_code === 'marwanrate');
      if (selectedEstimate) {
        estimatedPrice.value = selectedEstimate[0]?.price_incl_tax?.value;
      }
      // @ts-ignore: Unreachable code error
      estimatedDays.value = estimateData?.estimateShippingMethods[0]?.estimated_days
      if(estimatedDays.value == null) {
        if (gccList.value.includes(selCountryId.value)) {
          estimatedDays.value = '7 days';
        } else if (selCountryId.value === 'AE') {
          estimatedDays.value = '3 days';
        } else {
          estimatedDays.value = 'more than 30 days';
        }
      }
      else {
        estimatedDays.value = estimatedDays.value +' days'
      }
    }

    const changeCountry = async (id: string) => {
      changeShippingDetails('country_code', id);
      const newCountry = await searchCountry({id});
      shippingDetails.value.region = '';
      country.value = newCountry;
      citiesList.value = await getCities(id, "")
      selCountryId.value = country.value?.id
    };

    const onSelectCode = (event) => {
      countryCode.value = event.dialCode;
    };

    const setActive = (item) => {
      activeItem.value = item;
    }
    const clearInput= (item) =>{
      form.value[item]='';
      if (activeItem.value === item) {
        activeItem.value = '';
      }
    }
    const validateEmail = (email) => {
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return pattern.test(email);
    }

    const getEmail = () => {
      if (email.value) {
        emailValidation.value = !validateEmail(email.value);
        updateCustomerNotes()
      } else {
        emailValidation.value = true
      }
    }

    const updateCustomerNotes = () => {
      let customerData = {
        email: email.value,
        comments: customerNotes.value,
        selectDate: selectedDate.value,
        selectTime: selectedTime.value,
      }
      dataForShippingMethod.value.customer_notes = JSON.stringify(customerData)
      dataForShippingMethod.value.method = 'marwanrate_marwanrate'
      if (dataForShippingMethod.value.customer_address_id && email.value) {
        dataForShippingMethod.value.dropDown = true
        emit('dataForShippingMethod', dataForShippingMethod.value)
      }
    }

    const loadDefaultShippingAddress = async () => {
      showAddressBook.value = true;
      const [loadedUserShipping] = await Promise.all([
        loadUserShipping()
      ]);
      // const [defaultAddress = null] = userShippingGetters.getAddresses(loadedUserShipping, { default_shipping: true });
      userShipping.value = loadedUserShipping;
    }

    const sameShippingCheck = () => {
      dataForShippingMethod.value.sameForAll = sameShippingForAllItems.value
      if (dataForShippingMethod.value.customer_address_id !== 0) {
        emit('dataForShippingMethod', dataForShippingMethod.value)
      }
    }

    const selectedAddrInSaved = (loadedShippingVals) => {
      //@ts-ignore
      let selAddrId = props.addedAddress?.cart_items_v2?.customer_address_id;
      let addressAvailable = false;

      loadedShippingVals?.addresses?.forEach(element => {
        if(element.customer_address_id === selAddrId) {
          selCityName.value = element.city
          handleSetCurrentAddress(element)
          addressAvailable =  true;
          loadDefaultShippingAddress()
          showAddressBook.value = true;
        }
      });
      return addressAvailable
    }

    onMounted(async () => {
      const [loadedCountries, loadedUserShipping] = await Promise.all([
        loadCountries(),
        loadUserShipping()

      ]);
      countries.value = loadedCountries;
      email.value = loadedUserShipping?.email
      if(Object.keys(props.addedAddress).length !== 0)  {
        if(!selectedAddrInSaved(loadedUserShipping)) {

          form.value.apartment= props.addedAddress?.['apartment']
          if(props.addedAddress?.['country']?.code) {
            await changeCountry(props.addedAddress?.['country']?.code)
          }
          let city = citiesList.value.find((city) => city.code ===  props.addedAddress?.['city'])
          if (city) form.value.city = city.code
          form.value.country_code= props.addedAddress?.['country']?.code
          form.value.firstname= props.addedAddress?.['firstname']
          form.value.lastname= props.addedAddress?.['lastname'] || 'test'
          form.value.postcode= props.addedAddress?.['postcode']
          form.value.street= props.addedAddress['street']?.toString()
          form.value.telephone= props.addedAddress?.['telephone']
          form.value.default_shipping= props.addedAddress?.['default_shipping'] || false
          form.value.default_billing= props.addedAddress?.['default_billing'] || false
          //submitForm();
        }
      }
    });

    return {
      timeList,
      showSetAsFutureAddress,
      isSubmitting,
      cartProductQuantity,
      sameShippingCheck,
      handleMutation,
      observeFormChanges,
      sameShippingForAllItems,
      city,
      preSelectedAddress,
      submitBtn,
      getEmail,
      emailValidation,
      changeCity,
      citiesList,
      selectedDate,
      selectedTime,
      updateCustomerNotes,
      removeDefaultSelectedAddress,
      customerNotes,
      showAddressBook,
      changeAddressPhone,
      submitForm,
      form,
      formRef,
      loadDefaultShippingAddress,
      defaultShipping,
      email,
      isAddNewAddressFormVisible,
      canMoveForward,
      changeCountry,
      changeShippingDetails,
      countries,
      countriesList,
      country,
      currentAddressId,
      handleAddNewAddressBtnClick,
      handleSetCurrentAddress,
      hasSavedShippingAddress,
      isAuthenticated,
      isFormSubmitted,
      isShippingDetailsStepCompleted,
      isShippingLoading,
      regionInformation,
      searchCountry,
      isSetAsDefaultRequested,
      shippingDetails,
      shippingMethods,
      addresses,
      shippingAdressFilled,
      $patch,
      countryCode,
      onSelectCode,
      setActive,
      activeItem,
      selCityName,
      estimatedPrice,
      estimatedDays,
      clearInput,
      getCountryList
    };
  },
});
