















import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import CartItem from '~/modules/checkout/pages/Checkout/ShoppingCart/CartItem.vue';
import TotalSection from '~/modules/checkout/pages/Checkout/ShoppingCart/TotalSection.vue';
import type { Product } from '~/modules/catalog/product/types';
import type { CartItemInterface } from '~/modules/GraphQL/types';
import {useCartStore} from "~/modules/checkout/stores/cart";
import {defineComponent, onMounted, PropType, computed, ref} from "@nuxtjs/composition-api";


export default defineComponent({
  name: 'ShoppingCart',
  components: {
    SkeletonLoader,
    CartItem,
    TotalSection
  },
  data() {
    return {
      activeStep: 1,
      pageReady: true,
      expand: false
    }
  },
  props: {
    products: {
      type: Array as PropType<CartItemInterface[]>,
      default: 0,
    },
    selectedCurrency: {
      type: String,
      default: 'USD'
    }
  },
  setup(props) {
    const selectedFilter = ref([]);
    const store = useCartStore();
    const addFilter = (filter) => {
      if(selectedFilter.value.includes(filter)){
        const index = selectedFilter.value.indexOf(filter);
        if (index > -1) { // only splice array when item is found
          selectedFilter.value.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
      else {
        selectedFilter.value.push(filter)
      }
    }
    const filteredProds = computed(()=> {
      if(selectedFilter.value.includes('Offers') && !selectedFilter.value.includes('Equipments') && !selectedFilter.value.includes('Attachments') ) {
        return props?.products?.filter(item => item['custom_option']?.reserve_makeoffer > 0)
      }
      else if(selectedFilter.value.includes('Offers') && selectedFilter.value.includes('Equipments') && !selectedFilter.value.includes('Attachments') ) {
        return props?.products?.filter(item => item['custom_option']?.reserve_makeoffer > 0) || props?.products?.filter(item =>
        item?.product?.categories?.some(ele =>
        ele.name === 'Equipment'));
      }
      else if(selectedFilter.value.includes('Offers') && !selectedFilter.value.includes('Equipments') && selectedFilter.value.includes('Attachments') ) {
        return props?.products?.filter(item => item['custom_option']?.reserve_makeoffer > 0) || props?.products?.filter(item =>
        item?.product?.categories?.some(ele =>
        ele.name === 'Attachments'));
      }
      else if(!selectedFilter.value.includes('Offers') && selectedFilter.value.includes('Equipments') && !selectedFilter.value.includes('Attachments') ) {
        return props?.products?.filter(item =>
        item?.product?.categories?.some(ele =>
        ele.name === 'Equipment'));
      }
      else if(!selectedFilter.value.includes('Offers') && !selectedFilter.value.includes('Equipments') && selectedFilter.value.includes('Attachments') ) {
        return props?.products?.filter(item =>
        item?.product?.categories?.some(ele =>
        ele.name === 'Attachments'));
      }
      else {
        return props?.products;
      }
    });

    onMounted(() => {
      store.onCheckoutPaymentPage = false
    })

    return {
      filteredProds,
      addFilter,
      selectedFilter
    }
  },
})
