



































import { defineComponent, onMounted, ref } from "@nuxtjs/composition-api";
import ShippingMethods from "~/modules/checkout/pages/Checkout/Delivery/ShippingMethods.vue";


export default defineComponent({
  name: 'Product',
  components: {
    ShippingMethods
  },
  props: {
    productNumber: {
      type: Number,
      required: true
    },
    product: {
      type: [Array, Object],
      required: true
    },
    accordion: {
      type: [Array, Object],
      required: true
    },
    makeOfferArr: {
      type: Array,
      required: true
    },
    financeProdArr: {
      type: Array,
      required: true
    },
    disabled: {
      type: [String, Number],
      default: ''
    },
    firstItemSku: {
      type: String,
      default: ''
    },
    addedAddress: {
      type: [Array, Object],
      required: true
    }
  },
  setup(props, {emit}) {
    // @ts-ignore
    const itemId = props.product?.item_id
    // @ts-ignore
    const itemNumber = props.product?.number
    const activeSku = ref('');
    const productShippingAddresses = ref(
      {
        item_id: itemId,
        quantity: 1,
        customer_address_id: null,
        customer_notes: "",
        number: itemNumber,
        method: '',
        sameForAll: false
      }
    )
    const clickAccord = (sku) => {
      if(!isMakeOfferProd(sku)) {
        activeSku.value = ''
        // @ts-ignore
        props.accordion?.isOpen = !props.accordion?.isOpen
        // @ts-ignore
        emit('click-accordion', { product: props.accordion?.product, isOpen: props.accordion?.isOpen} )
      }
    }

    const shippingSelected = ({ data }) => {
      productShippingAddresses.value.customer_address_id = data.customer_address_id
      productShippingAddresses.value.customer_notes = data.customer_notes
      productShippingAddresses.value.method = data.method
      productShippingAddresses.value.sameForAll = data.sameForAll
      // @ts-ignore
      props.accordion?.isOpen = data.dropDown
      if (productShippingAddresses.value.customer_address_id || productShippingAddresses.value.customer_address_id === 0) {
        emit('productShippingAddresses', { address: productShippingAddresses.value})
      }
      // document.documentElement.scrollIntoView({ behavior: "smooth" })
    }

    const isMakeOfferProd = (sku) => {
      return props.makeOfferArr.length > 0 && props.makeOfferArr.includes(sku);
    }
    const isFinanceProd = (sku) => {
      return props.financeProdArr.length > 0 && props.financeProdArr.includes(sku);
    }

    const getSelectedMethod = () => {
      // @ts-ignore
      if(props.addedAddress.lastname == 'Head office') {
        return 'pickUp'
      }
      else {
        // @ts-ignore
        if(props.product?.shippable_countries?.length) {
          return 'delivery'
        }
        else {
          return 'pickUp'
        }
      }
    }
    const getSelectedAddress = () => {
      // @ts-ignore
      if(props.addedAddress.lastname == 'Head office') {
        return []
      }
      else {
        return props.addedAddress
      }
    }

    onMounted(async() => {
      if(isMakeOfferProd(props.product['sku'])) {
        productShippingAddresses.value.customer_address_id = 0
        productShippingAddresses.value.customer_notes = ''
        productShippingAddresses.value.method = 'flatrate_flatrate'
        if (productShippingAddresses.value.customer_address_id || productShippingAddresses.value.customer_address_id === 0) {
          emit('productShippingAddresses', { address: productShippingAddresses.value})
        }
      }

      if(props.firstItemSku !== '' && props.product['sku'] ===  props.firstItemSku) {
        activeSku.value = props.firstItemSku;
        clickAccord(props.product['sku'])
      }
    })

    return {
      shippingSelected,
      clickAccord,
      isMakeOfferProd,
      isFinanceProd,
      activeSku,
      getSelectedMethod,
      getSelectedAddress
    }
  }
})
