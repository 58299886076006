var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"billing-div"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"section-title"},[_c('p',[_vm._v("Billing Details")])]),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.handleAddressSubmit())}}},[(!_vm.multiShippingAddresses)?[(_vm.showSameShippingCheckbox && _vm.showSameAsShippingAddressCheckbox)?_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('copy-address'),expression:"'copy-address'"}],staticClass:"form__element billing-check",attrs:{"selected":_vm.sameAsShipping,"label":_vm.$t('Same as Shipping Address'),"name":"copyShippingAddress"},on:{"change":function($event){return _vm.handleCheckSameAddress($event)}}}):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.isAddNewAddressFormVisible && !_vm.sameAsShipping)?_c('div',{staticClass:"form"},[_c('ValidationProvider',{attrs:{"name":"firstname","rules":"required|min:2|max:50","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('firstName'),expression:"'firstName'"}],staticClass:"form__element form__element--half billing-input full-length",class:[{ active: _vm.activeItem === 'firstName'} ],attrs:{"value":_vm.billingDetails.firstname,"placeholder":"Full Name","name":"firstName","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('firstname', $event)},"blur":function($event){return _vm.pushBillingForm()},"focus":function($event){return _vm.setActive('firstName')}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"street","rules":"required|min:2|max:200","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('streetName'),expression:"'streetName'"}],staticClass:"form__element form__element--half billing-input full-length",class:[{ active: _vm.activeItem === 'streetName'} ],attrs:{"value":_vm.billingDetails.street,"placeholder":"Address","name":"streetName","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('street', $event)},"blur":function($event){return _vm.pushBillingForm()},"focus":function($event){return _vm.setActive('streetName')}}})]}}],null,true)}),_vm._v(" "),_c('SfInput',{staticClass:"form__element form__element--half billing-input full-length",class:[{ active: _vm.activeItem === 'streetName'} ],attrs:{"value":_vm.customerEmailAddress,"placeholder":"Email","name":"streetName","disabled":true}}),_vm._v(" "),_c('div',{staticClass:"country-city-row"},[_c('ValidationProvider',{attrs:{"name":"country_code","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfSelect',{directives:[{name:"e2e",rawName:"v-e2e",value:('country'),expression:"'country'"}],staticClass:"form__element form__element--half form__select sf-select--underlined billing-input",attrs:{"value":_vm.billingDetails.country_code,"label":"Country","name":"country","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":_vm.changeCountry,"blur":function($event){return _vm.pushBillingForm()}}},[_c('SfSelectOption',{attrs:{"value":''}}),_vm._v(" "),_vm._l((_vm.countriesList),function(countryOption){return _c('SfSelectOption',{key:countryOption.code,attrs:{"value":countryOption.code}},[_vm._v("\n              "+_vm._s(countryOption.name)+"\n            ")])})],2)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"city","rules":"required|min:2|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('city'),expression:"'city'"}],staticClass:"form__element form__element--half billing-input city",class:[{ active: _vm.activeItem === 'city'} ],attrs:{"value":_vm.billingDetails.city,"placeholderplaceholder":"City","name":"city","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('city', $event)},"blur":function($event){return _vm.pushBillingForm()},"focus":function($event){return _vm.setActive('city')}}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"phone-col"},[_c('ValidationProvider',{attrs:{"name":"countryCodeVal","slim":""}},[_c('vue-country-code',{ref:"countryCodeVal",staticClass:"phone-code-list billing-input",attrs:{"preferredCountries":['ae', 'sa', 'om'],"enabledCountryCode":true,"defaultCountry":'ae'},on:{"onSelect":_vm.onSelectCode}})],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"telephone","rules":"required|min:8|numeric|max:12","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('phone'),expression:"'phone'"}],staticClass:"form__element form__element--half billing-input",class:[{ active: _vm.activeItem === 'phone'} ],attrs:{"value":_vm.billingDetails.telephone,"placeholder":"Phone number","name":"phone","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('telephone', $event)},"blur":function($event){return _vm.pushBillingForm()},"focus":function($event){return _vm.setActive('phone')}}})]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form"},[_c('div',{staticClass:"form__action"},[(_vm.isAddNewAddressFormVisible)?_c('SfButton',{staticClass:"color-light form__action-button form__action-button--add-address",attrs:{"type":"submit","id":"billingSubmit"}},[_vm._v("\n            "+_vm._s(_vm.$t('Add new address'))+"\n          ")]):_vm._e()],1)])],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }