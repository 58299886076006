


































import { defineComponent, ref } from '@vue/composition-api'
import { emit } from 'process'
import BreadCrumb from "~/almarwan/components/Breadcrumb.vue";
import {useUiNotification} from "~/composables";
import {eventBus} from "~/almarwan/helpers/EventBus";

export default defineComponent({
  components: {
    BreadCrumb
  },
  props: {
    activeStep: {
      type: Object,
      required: true
    },
    breadcrumbs: {
      type: Array,
      required: true
    },
    quoteId: {
      type: Number,
      default: null
    },
    payBalanceOn: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { send } = useUiNotification();
    const clickable = ref(false)
    const setNewStep = (newStep) => {
      if(!props.payBalanceOn) {
        if(newStep <= 1){
          emit('newStep', newStep)
        }
        else if(newStep >= 2 && props.activeStep.id == 2) {
          eventBus.$emit('proceedToPayment')
        }
      }
    }

    return {
      setNewStep,
      clickable
    }
  },
})
