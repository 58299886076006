


































import {defineComponent, onMounted, ref} from "@nuxtjs/composition-api";
import useCart from "~/modules/checkout/composables/useCart";
import useUiHelpers from "../../../../composables/useUiHelpers";

export default defineComponent({
  name: 'Percentage',
  props: {
    product: {
      type: Object,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const payPercentCheck = ref('fullPrice');
    const { updateInstallation } = useCart();
    const { numberWithCommas } = useUiHelpers()
    const payPercentage = (product, val) => {
      payPercentCheck.value = val
      if (val === 'deposit') {
        if(product.customizable_options && product.customizable_options?.length > 0) {
          const customOptionId = product?.customizable_options?.map(option => {
            const valueString = option.values[0].value;
            return {
              id: option.id,
              value_string: valueString
            };
          });
          updateInstallation({product, quantity: props.quantity, customizable_options: customOptionId, custom_option: { reserve_order: true }} )
        } else {
          updateInstallation({product, quantity: props.quantity, custom_option: { reserve_order: true }} )
        }
      }
      if (val === 'fullPrice') {
        if (product.customizable_options && product.customizable_options?.length > 0) {
          const customOptionId = product?.customizable_options?.map(option => {
            const valueString = option.values[0].value;
            return {
              id: option.id,
              value_string: valueString
            };
          });
          updateInstallation({ product, quantity: props.quantity, customizable_options: customOptionId } )
        } else {
          updateInstallation({ product, quantity: props.quantity } )
        }
      }
    }

    onMounted(() => {
      payPercentCheck.value = props.product.custom_option.reserve_order ? 'deposit' : 'fullPrice'
    })

    return {
      payPercentCheck,
      payPercentage,
      numberWithCommas
    }
  }
})
